import React, { useState } from "react";

import swal from "sweetalert";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import "../../../App.css";
import Navbar from "../../../layouts/frontend/Navbar";

function RecoverPassword() {
  const history = useHistory();
  const [loginInput, setLogin] = useState({
    email: "",
    password1: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: loginInput.email,
      password1: loginInput.password1,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          history.push("/");
          // if (res.data.role === "admin") {
          //   history.push("/admin/dashboard", ...res.data.username);
          // } else {
          //   history.push("/");
          // }
        } else if (res.data.status === 401) {
          swal("Warning", res.data.message, "warning");
        } else {
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
        }
      });
    });
  };

  return (
    <>
      <Navbar />

      <section class="py-0 font-1">
                <div class="container-fluid">
                    <div class="row align-items-center text-center justify-content-center h-full">
                        <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                            <h3 class="mb-3 fw-300">Forgot Password?</h3>
                            <p>Enter your email used in registration </p>
                            <form>
                                <div class="input-group">
                                    <input class="form-control " type="email" placeholder="Email"/>
                                    <span class="input-group-btn">
                                        <button class="btn btn-primary color-vhospitalilty-2" type="button">Next</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}

export default RecoverPassword;
