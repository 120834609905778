import React, {useState} from 'react'

import video from "../assets/admin/assets/img/prod.mp4"

import { Link } from 'react-router-dom';
import Search from '../components/frontend/Search/Search'

function HomeSlideIntro() {
    const [showSearch, setShowSearch] = useState(false);


    return (
        <section class="color-white py-6" id="header-video">
            {showSearch && <Search />}
            <div class="background-holder overlay overlay-2" style={{ "background-image": "url(assets/images/education-video-header.jpg)" }}>
                <video autoplay="autoplay" loop="loop" muted="muted">
                    <source src={video} type="video/webm" />
                    <source src={video} type="video/mp4" />
                    <source src={video} type="video/ogv" /> </video>
            </div>
            <div class="container">
                <div class="row justify-content-center h-half align-items-center py-8">
                    <div class="col-lg-8 pl-xl-5 text-center">
                        <h1 class="mb-3"><strong>Enjoy the freedom of a monthly stay at V-Hospitality</strong> </h1>
                        <strong><p class="lead text-white font-1 mt-2 mb-5 color-5">Rent a property in less than 5 minutes!</p></strong>
                        {/* <Link class="btn color-vh-button btn-lg text-center"
                            to={!localStorage.getItem("auth_token") ? ("/register") : ("/search-homes")}>Explore</Link> */}
                        
                        

                    </div>
                    {/* <button onClick={() => setShowSearch(!showSearch)} className='banner__searchButton' variant='outlined'>
                            {showSearch ? "Hide" : "Search Property"}
                        </button> */}
                </div>
            </div>
        </section>
    )
}

export default HomeSlideIntro;