import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import "../../App.css";
import SearchIcon from "@material-ui/icons/Search";
import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core";

import Footer from "./Footer";
import Navbar from "../../layouts/frontend/Navbar";
import swal from "sweetalert";
import img from "../../assets/admin/assets/img/logo1.png";
import Search from "./Search/Search";
import Mapbox from "../frontend/MapBox/Mapbox";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker";

import error_image from "../../assets/admin/assets/img/error.jpg";
import FirstNavbar from "../../layouts/frontend/FirstNavbar";
import HomeSlideIntro from "../../ReusedComponents/HomeSlideIntro";

function AllPropertyAvailable() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [viewProduct, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [searchInput, setSearchInput] = useState("");

  const [q, setQ] = useState("");
  const [searchParam] = useState(["brand", "name", "location"]);
  const [filterParam, setFilterParam] = useState(["All"]);
  const [showSearch, setShowSearch] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    console.log(startDate, endDate);
    setFilterParam({ startDate, endDate });
  };

  useEffect(() => {
    document.title = "All Property Listing";
    axios.get("/api/view-homes").then((res) => {
      if (res.data.status === 200) {
        setProduct(res.data.houses);
        setLoading(false);
      }
    });
  }, []);

  const data = Object.values(viewProduct);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  // WISHLIST
  const addToWishList = (e, property_id) => {
    e.preventDefault();

    const data = {
      product_id: property_id,
      product_qty: quantity,
    };

    // console.log('added my brother');
    axios.post(`/api/add-to-wishlist`, data).then((res) => {
      if (res.data.status === 200) {
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(res.data.cart);
      } else if (res.data.status === 409) {
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(res.data.cart);
      } else if (res.data.status === 401) {
        //Unauthenticated
        swal("Error", res.data.message, "error");
        history.push("/login");
      } else if (res.data.status === 404) {
        //Not Found
        swal("Warning", res.data.message, "warning");
        history.push("/login");
      }
    });
  };

  var loadingTag = `<img src=${img} width="120px" />
  <ReactLoading type={"bars"} color={"black"} height={80} width={80} />`;

  const [isVisible, setVisible] = useState(false);
  const handleClick = () => {
    // setVisible({data: `true`});
    setVisible((isVisible) => !isVisible);
    console.log(`isVisible is ${isVisible}`);
  };

  var display_Productdata = "";
  if (loading) {
    return (
      // <div className="ReactLoadingCenter" align="center">{Parser(loadingTag)}</div>
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    display_Productdata = search(data).map((item, idx) => {
      return (
        <>
          <div className="col-md-4 col-sm-6 mb-6 align-self-stretch" key={idx}>
            {/* <i className="flag flag-uae"></i> */}
            <Link
              className="color-1 underlink_removal"
              to={`/home/${item.id}`}
              style={{
                "text-transform": "uppercase",
                "text-decoration": "none",
              }}
            >
              <div className="card radius-square">
                <LazyLoadImage
                  alt={item.name}
                  effect="blur"
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                />
                <div className="card-block p-2">
                  <div className="row align-items-center mt-3">
                    <div className="col-12">
                      <div className="color-5 mt-2">
                        <button
                          className="btn btn-light color-5 fs--1 float-end"
                          onClick={(e) => addToWishList(e, item.id)}
                        >
                          <span className="fa  fa-heart fs--1 "></span>
                        </button>
                        <ToastContainer />
                      </div>

                      <p
                        className="fs--4 color-1 font-1"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <strong>{item.name}</strong>
                      </p>
                    </div>
                    <div className="col">
                      <h5 className="fs-4 d-inline-block mb-0 color-vhospitalilty ">
                        NGN {item.selling_price}
                        <span className="color-5 fs--1 fw-400">/ night]</span>
                      </h5>
                    </div>
                  </div>
                  <div className="row my-2">
                    <span className="color-5 fs--1 fw-400 mb-2">
                      {" "}
                      {item.status === 0 ? (
                        <div class="badge badge-warning ">Available</div>
                      ) : (
                        <div class="badge badge-warning ">Not Available</div>
                      )}{" "}
                      | <span className="fa fa-map-marker mr-1"></span>{" "}
                      {item.location} | {" "}<span className="fa fa-user mr-1"></span>{" "} {item.qty}
                    </span>
                    <div className="col-12">
                      <div className="d-inline-block color-vhospitalilty">
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                      </div>
                      <div className="d-inline-block fs--1">
                        {item.category.name}
                      </div>
                      <div>
   
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </>
      );
    });
    // viewProduct.map((item, idx) => { });
  }

  if (display_Productdata.length > 0) {
    return (
      <>
        {/* NAVBAR -------------------------- */}

        <FirstNavbar />
        <div className="background-11 color-2 py-0 shadow sticky-top">
          <Navbar />

          <div className="banner__search font-1">
            

            <a className='banner__searchButton' variant='outlined' style={{"margin":"auto"}}>
            “Curating the essence of great living... Experience the V-lifestyle”
                </a>
          </div>

          {/* <input
            type="search"
            style={{ "font-size": "18px" }}
            className="form-control input-lg input_border text-center"
            placeholder="I want to rent a property located in ...."
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="banner__search font-1">
            {searchInput && <Search location={searchInput} />}


          </div> */}
        </div>

        {/* <HomeSlideIntro /> */}

        <section className="font-1 py-5">
          {/* <IconSearchBar /> */}

          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <div className="row mt-2 mb-3 ">
                  <p className="fs-4">
                    1 - {viewProduct.length} of {viewProduct.length} results{" "}
                    <span class="badge mr-1  color-vhospitalilty-b">
                      {" "}
                      Properties for rent
                    </span>{" "}
                  </p>
                  <div className="col-lg-8 col-md-8">
                    <div className="row">{display_Productdata}</div>
                  </div>
                  <div className="col-lg-4 col-md-6 hidden-md-down">
                    <div className="mt-0  " style={{}}>
                      <div>
                        <h3
                          className=" fw-400  color-vhospitalilty "
                          style={{ "text-transform": "uppercase" }}
                        >
                          <span className="icon-Video-4 fs-4 "></span>{" "}
                          <strong>Virtual Tour</strong>
                        </h3>
                        <hr className="short left my-3 color-1" align="left" />
                        <p>
                          Host of the Week{" "}
                          <span class="badge mr-1 badge-default">
                            {" "}
                            Vanern Residence
                          </span>{" "}
                        </p>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          height: "0",
                          "padding-top": "100%",
                          // "border-radius":"10px"
                        }}
                      >
                        <iframe
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            "border-radius": "15px",
                            "box-shadow":
                              "0px 6px 18px -9px rgba(0, 0, 0, 0.25)",
                          }}
                          src="https://virtrav.com/tour/vanernresidence/"
                          name="Vanern Residence"
                          width="100%"
                          height="100%"
                          frameborder="0"
                          allowfullscreen="true"
                          allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; autoplay; camera; microphone"
                        ></iframe>
                      </div>
                    </div>

                    <div
                      className="background-11 sticky-top mt-7"
                      style={{
                        // height: "30%",
                        "border-radius": "10px",
                        "box-shadow": "0px 6px 18px -9px rgba(0, 0, 0, 0.75)",
                      }}
                    >
                      <Mapbox houseprop={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  } else {
    return (
      <>
        {/* NAVBAR -------------------------- */}

        <FirstNavbar />
        <div className="background-11 color-2 py-0 shadow sticky-top">
          <Navbar />
          {/* <div className="background-11 color-2 py-0 shadow sticky-top">
          <button className="btn btn-light float-right" onClick={handleClick}>Search Property
          </button></div> */}
          {/* <Link className="navbar-brand" to="/">
                  <img src={img} width="60px" /> V-HOSPITALITY{" "}
                </Link> */}

          <div
            className={`row justify-content-end h-half align-items-center py-4 
         `}
          >
            <div className="col-lg-12 col-md-4 col-sm-6">
              <div className="container">
                <h4 className=" mb-3  text-right ">
                  <span className="mr-1 fs-1 fa fa-search  mr-1"></span>{" "}
                  <strong>SEARCH PROPERTY</strong>
                  {/* <img src={img} width="40px" />{" "} */}
                </h4>

                <form>
                  <div className="row">
                    <div className="col-lg-3 col-md-6  color-vhospitalilty">
                      <label>Destination</label>
                      {/* <hr /> */}
                      <form className="mb-4">
                        <div className="form-group ">
                          <select
                            name="houseType"
                            className="form-control input-lg"
                            style={{ width: "300px", height: "auto" }}
                            onChange={(e) => {
                              setFilterParam(e.target.value);
                            }}
                            // onChange={this.props.change}
                          >
                            <option value="All">All Types</option>
                            <option value="Asokoro">Asokoro</option>
                            <option value="Gudu">Gudu</option>
                            <option value="Gwarinpa">Gwarinpa</option>
                            <option value="Idu">Idu</option>
                            <option value="Jabi">Jabi</option>
                            <option value="Jahi">Jahi</option>
                            <option value="Lugbe">Lugbe</option>
                            <option value="Life-camp">Life-camp</option>
                            <option value="Katampe">Katampe</option>
                            <option value="Maitama">Maitama</option>
                          </select>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-3 col-md-6  color-vhospitalilty">
                      <label>Check-in/Check-out Date</label>

                      <DatePicker
                        className="form-control input-lg"
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        // inline
                      />
                    </div>
                    {/* <div className="col-lg-3 col-md-6 color-vhospitalilty">
                      <label>Check-out Date</label>
                      <DatePicker
                        className="form-control input-lg"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                    </div> */}
                    <div className="col-lg-3 col-md-6 color-vhospitalilty">
                      <label>Guests</label>
                      <input
                        className="form-control input-lg"
                        type="number"
                        value="1"
                        min="1"
                      />{" "}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label>&nbsp;</label>
                      <input
                        className="btn btn-warning btn-block btn-lg color-vhospitalilty-b"
                        // type="submit"
                        value="Check Availability"
                        onClick={""}
                      />{" "}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <section className="font-1 py-5">
          <div className="row pr-4 pl-4 pb-4">
            <div className="col-12">
              <form>
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control input-lg input_border"
                    placeholder="Search by brand, name, or location"
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <span className="input-group-btn">
                    <button className="btn color-vhospitalilty-b" type="button">
                      <span className="icon-Magnifi-Glass2 fs-4"></span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {/* BEGINING OF FILTER */}

              <div className="col-2 hidden-md-down">
                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-AllPropertyAvailable color-vhospitalilty mr-1"></span>{" "}
                  Property Type{" "}
                </small>

                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="houseType"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                      // onChange={this.props.change}
                    >
                      <option value="All">All Types</option>
                      <option value="Varnern Residence">
                        Varnern Residence
                      </option>
                      <option value="V-Hotel">V-Hotel</option>
                      <option value="V-Apartments">V-Apartments</option>
                      <option value="V-AllPropertyAvailables">
                        V-AllPropertyAvailables
                      </option>
                    </select>
                  </div>
                </form>

                <small className="mt-3 ">
                  <span className="mr-1 fs-4 fa fa-location-arrow color-vhospitalilty mr-1"></span>{" "}
                  Locations in Abuja{" "}
                </small>

                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="neighborhood"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                    >
                      <option value="All">All Locations</option>
                      <option value="Maitama">Maitama</option>
                      <option value="Abuja">FCT</option>
                      <option value="Jabi">Jabi</option>
                      <option value="Katampe">Katampe</option>
                      <option value="Wuse">Wuse</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-AllPropertyAvailable color-vhospitalilty mr-1"></span>{" "}
                  Type of place{" "}
                </small>

                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      onChange={(e) => {
                        setFilterParam(e.target.value);
                      }}
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                    >
                      <option value="All">Filter By House</option>
                      <option value="Penthouse 1">Penthouse 1</option>
                      <option value="Penthouse 2">Penthouse 2</option>
                      <option value="Premium Suite">Premium Suite</option>
                      <option value="V Premium">V Premium</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-bathtub color-vhospitalilty mr-1"></span>{" "}
                  No. of Bathroom
                </small>

                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="baths"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      // onChange={this.props.change}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">More than 5</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-bed color-vhospitalilty mr-1"></span>{" "}
                  No. of Beds
                </small>

                <form className="d-inline-block form-inline mb-4">
                  <div className="form-group mr-3">
                    <select
                      name="bedrooms"
                      className="form-control d-inline-block ml-2"
                      style={{ width: "200px", height: "auto" }}
                      // onChange={this.props.change}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">More than 5</option>
                    </select>
                  </div>
                </form>

                <small className="mt-4">
                  <span className="mr-1 fs-4 fa fa-check-circle color-vhospitalilty mr-1"></span>{" "}
                  Facilities &amp; Extras
                </small>

                <div className="form-check mb-4">
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="pool"
                      value="pool"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Swimming Pool
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="two_car_garage"
                      value="two_car_garage"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Garage{" "}
                  </label>
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="finished_basement"
                      value="finished_basement"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Finished Basement{" "}
                  </label>{" "}
                  {/* TO BE ADDED ------------------------------------------- */}
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="wifi"
                      value="wifi"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Free WiFi{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="Washer"
                      value="Washer"
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Washer{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="Restaurant "
                      value="Restaurant "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Restaurant{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="RoomService "
                      value="RoomService "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Room Service{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="kitchen "
                      value="kitchen "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Dedicated Kitchen{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="RoomService "
                      value="RoomService "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    24 Hour Front Desk{" "}
                  </label>{" "}
                  <br />
                  <label className="form-check-label fw-400">
                    <input
                      className="form-check-input mr-2"
                      name="FacilitiesForDisabledGuest "
                      value="FacilitiesForDisabledGuest "
                      type="checkbox"
                      // onChange={this.props.change}
                    />
                    Facilities For Disabled Guest{" "}
                  </label>{" "}
                  <br />
                </div>

                <small className="mt-3"> Avg. Customer Review</small>
              </div>

              {/* END OF FILTER */}

              <div className="col-lg-10 pl-lg-5">
                <div className="row pb-3 align-items-center">
                  <div className="col">
                    <p className="mb-0">
                      {" "}
                      1- {viewProduct.length} of {viewProduct.length} results
                      for
                      <strong>
                        {" "}
                        PROPERTY FOR :
                        <span className="color-vhospitalilty">
                          {" "}
                          RENT
                          {/* <Link to={`/test2`}>RENT</Link> */}
                        </span>
                      </strong>
                    </p>
                  </div>
                  <div className="col-md-5 text-md-right">
                    <label for="exampleInputName">Sort by</label>
                    <form className="d-inline-block form-inline">
                      <div className="form-group mr-3">
                        <select
                          onChange={(e) => {
                            setFilterParam(e.target.value);
                          }}
                          className="form-control d-inline-block ml-2"
                          style={{ width: "200px", height: "auto" }}
                        >
                          <option value="All">All Types</option>
                          <option value="Varnern Residence">
                            Varnern Residence
                          </option>
                          <option value="V-Hotel">V-Hotel</option>
                          <option value="V-Apartments">V-Apartments</option>
                          <option value="V-AllPropertyAvailables">
                            V-AllPropertyAvailables
                          </option>
                        </select>
                      </div>
                    </form>
                    {/* <a className="color-5 mr-1" href="shop-products-list.html">
                                          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxOSAxNCIgd2lkdGg9IjE5cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZXNjLz48ZGVmcy8+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSI+PGcgZmlsbD0iIzAwMDAwMCIgaWQ9IkNvcmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04Ny4wMDAwMDAsIC01MDkuMDAwMDAwKSI+PGcgaWQ9InZpZXctbGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODcuNTAwMDAwLCA1MDkuMDAwMDAwKSI+PHBhdGggZD0iTTAsOSBMNCw5IEw0LDUgTDAsNSBMMCw5IEwwLDkgWiBNMCwxNCBMNCwxNCBMNCwxMCBMMCwxMCBMMCwxNCBMMCwxNCBaIE0wLDQgTDQsNCBMNCwwIEwwLDAgTDAsNCBMMCw0IFogTTUsOSBMMTcsOSBMMTcsNSBMNSw1IEw1LDkgTDUsOSBaIE01LDE0IEwxNywxNCBMMTcsMTAgTDUsMTAgTDUsMTQgTDUsMTQgWiBNNSwwIEw1LDQgTDE3LDQgTDE3LDAgTDUsMCBMNSwwIFoiIGlkPSJTaGFwZSIvPjwvZz48L2c+PC9nPjwvc3ZnPg=="
                                              height="15"/>
                                      </a>
                                      <a className="color-5" href="shop-products-grid.html">
                                          <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik0wLDQzMiBMNyw0MzIgTDcsNDM5IEwwLDQzOSBaIE05LDQzMiBMMTYsNDMyIEwxNiw0MzkgTDksNDM5IFogTTAsNDQxIEw3LDQ0MSBMNyw0NDggTDAsNDQ4IFogTTksNDQxIEwxNiw0NDEgTDE2LDQ0OCBMOSw0NDggWiBNOSw0NDEiIGlkPSJSZWN0YW5nbGUgMTg0Ii8+PC9nPjwvZz48L3N2Zz4="
                                              height="15"/>
                                      </a> */}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <hr className="my-0 color-danger" />
                    <hr className="my-0 color-danger" />{" "}
                  </div>

                  <div className="container text-center">
                    <h4 className="display-5 mb-0 mt-8">
                      Sorry, No Property found
                    </h4>
                    <LazyLoadImage
                      alt="error_thumbnail"
                      effect="blur"
                      width="65%"
                      src={error_image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  }
}

export default AllPropertyAvailable;
