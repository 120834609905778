import React from 'react';
import { Link } from 'react-router-dom';
import "../../App.css"

function Page403() {
    return(
        <div class="container errorpage">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="text-center mt-4">
                                    <h1 class="display-1">403</h1>
                                    <p class="lead">Unauthorized | Forbidden</p>
                                    <p>Access to this resource is denied.</p>
                                    <Link to={`/`} className="btn btn-primary">
                                        <i class="fas fa-arrow-left me-1 " ></i>
                                        Return to Home
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

        // <div className=" errorpage">
        //     <div className="row justify-content-center">
        //         <div className="col-md-6">
        //             <div className="">
        //                 <h1>Page 403 | Forbidden </h1>
        //                 <p className='lead'>Access Denied.! As you are not an Admin</p>
        //                 <Link to={`/`}><button className="btn btn-primary">Back to Home</button></Link>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Page403;