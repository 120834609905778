import React from 'react';
import { Link } from 'react-router-dom';
import "../../App.css"
// import ""

function Page404() {
    return(
        // <div className=" errorpage">
        //     <div className="row justify-content-center">
        //         <div className="col-md-6">
        //             <div className="">
        //             <h1>Page 404 | Page Not Fount </h1>
        //                 <h5>Url / Page you are searching not found</h5>
        //                 <Link to={`/`}><button className="btn btn-primary">Back to Home</button></Link>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="text-center mt-4">
                                <h1 class="display-1">404</h1>
                                    {/* <img class="mb-4 img-error" src="../../assets/admin/assets/img/error-404-monochrome.svg" /> */}
                                    <p class="lead">This requested URL was not found on this server.</p>
                                    <Link to={`/`} className="btn btn-primary">
                                        <i class="fas fa-arrow-left me-1 " ></i>
                                        Return to Home
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
    );
}

export default Page404;

