import WishList from '../components/frontend/UserSettings/WishList';
import Account from '../components/frontend/UserSettings/Account';
import Track from "../components/frontend/UserSettings/Track";
import Reviews from "../components/frontend/UserSettings/Reviews";
import Notification from "../components/frontend/UserSettings/Notifications";
import Faqs from "../components/frontend/UserSettings/Faqs";


// const userRoutesList = [
//     { path: '/account', exact: true, name: 'Account' },
//     { path: '/account/dashboard', exact: true, name: 'Account', component: Account },
//     { path: '/account/wishlist', exact: true, name: 'WishList', component: WishList },
// ];


const userRoutesList = [
    { path: '/account', exact: true, name: 'Account' },
    { path: '/account/dashboard', exact: true, name: 'Account', component: Account },
    { path: '/account/wishlist', exact: true, name: 'WishList', component: WishList },
    { path: '/account/notifications', exact: true, name: 'Notification', component: Notification },
    { path: '/account/track', exact: true, name: 'Track', component: Track },
    { path: '/account/reviews', exact: true, name: 'Reviews', component: Reviews },
    { path: '/account/faqs', exact: true, name: 'Faqs', component: Faqs },



];

export default userRoutesList;