// import * as React from "react";
// import { createRoot } from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import App from "./App";



// import ReactDOM from 'react-dom';
// ReactDOM.render(
  // <BrowserRouter>
  //     <App />
  //   </BrowserRouter>, root);

  
  
// const root = document.getElementById("root");

// createRoot(root).render(
//   <React.StrictMode>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
//   </React.StrictMode>
// );


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();


