import React from 'react'
import Navbar from '../../layouts/frontend/Navbar';



 const About = () => {
  return (
    <>
    <Navbar />
      <h1>Welcome About</h1>

      <h3>Go to About</h3>
    </>
  )
}

export default About;
