import React from 'react'
import '../../App.css'


class HousesCounterWidget extends React.Component {
    constructor() {
        super()
        this.state = {
            name: 'Adam',
        }

        //   this.render = this.render.bind(this)
    }

    render() {
        console.log(this.props);
        return (
            <React.Fragment>
                <p className="mb-0"> 1- {this.props.globalState.filteredData.length} of {this.props.globalState.filteredData.length} results for
                    <strong> : HOMES FOR
                        <span className="color-warning"> RENT</span>
                    </strong>
                </p>

            </React.Fragment>

        )
    }


}

export default HousesCounterWidget;