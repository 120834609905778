import React from 'react'
import '../../App.css'


class MyFilter extends React.Component {
    constructor() {
        super()
        this.state = {
            name: 'Adam',
            showExtras: false,
        }

        this.neighborhoods = this.neighborhoods.bind(this)
        this.houseTypes = this.houseTypes.bind(this)
        this.beds = this.beds.bind(this)
        this.bathroom = this.bathroom.bind(this)
    }

    componentWillMount() {
        this.props.populateAction()
    }

    neighborhoods() {
        if (this.props.globalState.populateFormsData.neighborhoods !== undefined) {
            var { neighborhoods } = this.props.globalState.populateFormsData
            console.log(neighborhoods);
            return neighborhoods.map((item) => {
                return (
                    <option key={item} value={item}>{item}</option>
                )
            })
        }
    }

    houseTypes() {
        if (this.props.globalState.populateFormsData.houseTypes !== undefined) {
            var { houseTypes } = this.props.globalState.populateFormsData
            console.log(houseTypes);
            return houseTypes.map((item) => {
                return (
                    <option key={item} value={item}>{item}</option>
                )
            })
        }
    }

    beds() {
        if (this.props.globalState.populateFormsData.beds !== undefined) {
            var { beds } = this.props.globalState.populateFormsData
            console.log(beds);
            return beds.map((item) => {
                return (
                    <option key={item} value={item}>{item}+ br</option>
                )
            })
        }
    }

    bathroom() {
        if (this.props.globalState.populateFormsData.bathroom !== undefined) {
            var { bathroom } = this.props.globalState.populateFormsData
            console.log(bathroom);
            return bathroom.map((item) => {
                return (
                    <option key={item} value={item}>{item}+ ba</option>
                )
            })
        }
    }

    render() {
        console.log(this.props);
        return (
            <React.Fragment>
                <div className="col-2 hidden-md-down">
                    {/* <h6>What Are You Looking For</h6>
                    <hr className="color-9 my-2" /> */}
                    {/* <div className="form-check mb-4">
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Entire Place{" "}
                        </label>
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Private Room{" "}
                        </label>
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Shared Space{" "}
                        </label>
                    </div>

                    <h6>Property Type</h6>
                    <hr className="color-9 my-2" />
                    <div className="form-check mb-4">
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            House
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Apartment{" "}
                        </label>
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Guesthouse{" "}
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Hotel{" "}
                        </label>
                    </div>

                    <h6>Amenities</h6>
                    <hr className="color-9 my-2" />
                    <div className="form-check mb-4">
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            WiFi
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Washer{" "}
                        </label>
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Air conditioning{" "}
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Kitchen{" "}
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Dryer{" "}
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Heating{" "}
                        </label>
                    </div>

                    <h6>Price (in Naira) per night</h6>
                    <hr className="color-9 my-2" />
                    <div className="form-check mb-4">
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            Under 75,000
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            75,000 to 100,000{" "}
                        </label>
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            100,000 to 150,000{" "}
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" type="checkbox" />
                            150,000 &amp; Above{" "}
                        </label>
                    </div> */}

                    

                    <h6 className="mt-3 "><span className="mr-2 fs-1 fa fa-location-arrow color-vhospitalilty mr-1"></span> Locations in Abuja </h6>
                    <hr className="color-9 my-2" />
                    <form className="d-inline-block form-inline">
                            <div className="form-group mr-3" >  
                                <select name="neighborhood" className="form-control d-inline-block ml-2"
                                 style={{ width: "150px", height: "auto" }} onChange={this.props.change}>
                                <option value='All'>All Locations</option>
                                    {this.neighborhoods()}
                                </select>
                            </div>
                        </form>

                    
                    <h6 className="mt-4"><span className="mr-2 fs-1 fa fa-home color-vhospitalilty mr-1"></span> Property Type </h6>
                    <hr className="color-9 my-2" />
                    <form className="d-inline-block form-inline">
                            <div className="form-group mr-3">  
                                <select name="houseType" className="form-control d-inline-block ml-2"
                                 style={{ width: "150px", height: "auto" }} onChange={this.props.change}>
                                <option value='All'>All Types</option>
                                    {this.houseTypes()}
                                </select>
                            </div>
                        </form>


                    <h6 className="mt-4"><span className="mr-2 fs-1 fa fa-bathtub color-vhospitalilty mr-1"></span> No. of Bathroom</h6>
                    <hr className="color-9 my-2" />
                    <form className="d-inline-block form-inline">
                            <div className="form-group mr-3">  
                                <select name="baths" className="form-control d-inline-block ml-2"
                                 style={{ width: "150px", height: "auto" }} onChange={this.props.change}>
                                    {this.bathroom()}
                                </select>
                            </div>
                        </form>


                    <h6 className="mt-4"><span className="mr-2 fs-1 fa fa-bed color-vhospitalilty mr-1"></span> No. of Beds</h6>
                    <hr className="color-9 my-2" />
                    <form className="d-inline-block form-inline">
                            <div className="form-group mr-3">  
                                <select name="bedrooms" className="form-control d-inline-block ml-2"
                                 style={{ width: "150px", height: "auto" }} onChange={this.props.change}>
                                    {this.beds()}
                                </select>
                            </div>
                        </form>

                    <h6 className='mt-4'><span className="mr-2 fs-1 fa fa-check-circle color-vhospitalilty mr-1"></span> Facilities &amp; Extras</h6>
                    <hr className="color-9 my-2" />
                    <div className="form-check mb-4">
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='pool' value='pool' type="checkbox" 
                            onChange={this.props.change} />
                            Swimming Pool
                        </label>{" "}
                        <br />
                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='two_car_garage' value='two_car_garage' 
                            type='checkbox' onChange={this.props.change} />
                            Garage{" "}
                        </label>

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='finished_basement' value='finished_basement' 
                            type='checkbox' onChange={this.props.change} />
                            Finished Basement{" "}
                        </label>{" "}


                        {/* TO BE ADDED ------------------------------------------- */}

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='wifi' value='wifi' 
                            type='checkbox' onChange={this.props.change} />
                            Free WiFi{" "}
                        </label>{" "}<br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='Washer' value='Washer' 
                            type='checkbox' onChange={this.props.change} />
                            Washer{" "}
                        </label>{" "} <br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='Restaurant ' value='Restaurant ' 
                            type='checkbox' onChange={this.props.change} />
                            Restaurant {" "}
                        </label>{" "}
                        <br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='RoomService ' value='RoomService ' 
                            type='checkbox' onChange={this.props.change} />
                            Room Service {" "}
                        </label>{" "}
                        <br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='kitchen ' value='kitchen ' 
                            type='checkbox' onChange={this.props.change} />
                            Dedicated Kitchen {" "}
                        </label>{" "}
                        <br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='RoomService ' value='RoomService ' 
                            type='checkbox' onChange={this.props.change} />
                            24 Hour Front Desk {" "}
                        </label>{" "}
                        <br />

                        <label className="form-check-label fw-400">
                            <input className="form-check-input mr-2" 
                            name='FacilitiesForDisabledGuest ' value='FacilitiesForDisabledGuest ' 
                            type='checkbox' onChange={this.props.change} />
                            Facilities For Disabled Guest {" "}
                        </label>{" "}
                        <br />
                       
                    </div> 
                    

                    <h6 className="mt-3"> Avg. Customer Review</h6>
                    <hr className="color-9 my-2" />
                    <a href="#" className=''>
                        <input className="form-check-input mr-2" 
                            name='finished_basement' value='finished_basement' 
                            type='checkbox' onChange={this.props.change} />
                        <div className="color-warning">
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="d-inline-block fs--1 color-5">&amp; up</span>
                        </div>
                    </a>
                    <a href="#">
                    <input className="form-check-input mr-2" 
                            name='finished_basement' value='finished_basement' 
                            type='checkbox' onChange={this.props.change} />
                        <div className="color-warning">
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="d-inline-block fs--1 color-5">&amp; up</span>
                        </div>
                    </a>
                    <a href="#">
                    <input className="form-check-input mr-2" 
                            name='finished_basement' value='finished_basement' 
                            type='checkbox' onChange={this.props.change} />
                        <div className="color-warning">
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="d-inline-block fs--1 color-5">&amp; up</span>
                        </div>
                    </a>
                    <a href="#">
                    <input className="form-check-input mr-2" 
                            name='finished_basement' value='finished_basement' 
                            type='checkbox' onChange={this.props.change} />
                        <div className="color-warning">
                            <span className="fa fa-star mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="fa fa-star-o mr-1"></span>
                            <span className="d-inline-block fs--1 color-5">&amp; up</span>
                        </div>
                    </a>
                </div>

            </React.Fragment>

        )
    }


}

export default MyFilter;