import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import Navbar from "../../../layouts/frontend/Navbar";
import img from "../../../assets/admin/assets/img/logo1.png";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import Footer from "../Footer";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import error_image from "../../../assets/admin/assets/img/error.jpg";
import ProductViewPagePromo from "../../../ReusedComponents/ProductViewPagePromo";
import "../../../App.css";
import Search from "../Search/Search";

function ViewProduct(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);

  const [q, setQ] = useState("");
  const [searchParam] = useState(["slug", "name", "location"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const [searchInput, setSearchInput] = useState("");

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    let isMounted = true;

    const product_slug = props.match.params.slug;
    axios.get(`/api/fetchproducts/${product_slug}`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setProduct(res.data.product_data.product);
          setCategory(res.data.product_data.category);
          setLoading(false);
        } else if (res.data.status === 400) {
          swal("Warning", res.data.message, "");
        } else if (res.data.status === 404) {
          swal("Warning", res.data.message, "error");
          history("/collections");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [props.match.params.slug, history]);

  const data = Object.values(product);

  function search(items) {
    return items.filter((item) => {
      if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  // WISHLIST
  const addToWishList = (e, property_id) => {
    e.preventDefault();

    const data = {
      product_id: property_id,
      product_qty: quantity,
    };

    // console.log('added my brother');
    axios.post(`/api/add-to-wishlist`, data).then((res) => {
      if (res.data.status === 200) {
        //Created - Data Inserted
        // history.push("/account/wishlist");
        // swal("Success",res.data.message,"success");
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (res.data.status === 409) {
        //Already added to cart
        // history.push("/account/wishlist");
        // swal("Success",res.data.message,"success");
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (res.data.status === 401) {
        //Unauthenticated
        swal("Error", res.data.message, "error");
        history.push("/login");
      } else if (res.data.status === 404) {
        //Not Found
        swal("Warning", res.data.message, "warning");
        history.push("/login");
      }
    });
  };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var showProductList = "";
    // showProductList = product.map((item, idx) => {
    showProductList = search(data).map((item, idx) => {
      return (
        <>
          <Link
            className="color-1"
            style={{ "text-decoration": "none" }}
            to={`/collections/${item.category.slug}/${item.id}`}
          >
            <div
              style={{
                "border-radius": "10px",
                "box-shadow": "0px 6px 18px -9px rgba(0, 0, 0, 0.25)",
              }}
              class="row align-items-center background-11 mb-3 py-3 mx-0"
              key={idx}
            >
              <div class="col-lg-5">
                <LazyLoadImage
                  alt={item.name}
                  effect="blur"
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                />
              </div>

              <div class="col-lg-7 color-1">
                <p
                  className="fs--4 color-1 font-1"
                  style={{ "text-transform": "uppercase" }}
                >
                  <strong>{item.name}</strong>
                  <span className="color-5 fs--1 fw-400">
                    {" "}
                    | {item.location} |{" "}
                    {item.status === 0 ? (
                      <div class="badge badge-warning ">Available</div>
                    ) : (
                      <div class="badge badge-warning ">Not Available</div>
                    )}
                  </span>
                </p>
                {/* <p style={{ "text-decoration": "none", "text-align": "justify", "overflow":"hidden", "text-overflow":"ellipsis" }} className="color-5 font-1">
                    Experience royalty! Why have one room when you can have a whole apartment? 
                    </p> */}
                <h5 class="fs-3 d-inline-block mb-0 color-vhospitalilty ">
                  NGN {item.selling_price}{" "}
                  <span className="color-5 fs--1 fw-400">/ night]</span>
                </h5>
                <div class="color-warning">
                  {/* <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span>
                      <span class="fa fa-star mr-1"></span> */}
                </div>
                <div class="d-inline-block color-vhospitalilty">
                  <span class="fa fa-star mr-1"></span>
                  <span class="fa fa-star mr-1"></span>
                  <span class="fa fa-star mr-1"></span>
                  <span class="fa fa-star mr-1"></span>
                  <span class="fa fa-star mr-1"></span>
                </div>
                <div class="d-inline-block fs--1">{item.category.name}</div>

                <p className="text-dark fs--1 fw-300 ellipses font-1 mt-2" style={{"text-align":"justify"}}>
                    {item.description}
                  </p>

                <div className="color-5 mt-1">
                  <button
                    className="btn btn-light color-5 fs--1"
                    onClick={(e) => addToWishList(e, item.id)}>
                    Add to Wishlist <span class="fa  fa-heart fs--1 "></span>
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </Link>
        </>
      );
    });
  }

  if (showProductList.length > 0) {
    return (
      <div>
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-1">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
                <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                  <strong>{category.name} Collections</strong>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="alert alert-dark " role="alert">
          <Link to={`/all-property-avail`} className="underlink_removal">
            Home
          </Link>{" "}
          <span class="icon-Arrow-Right2"></span> {category.slug}
        </div>

        {/* <div className="container mt-6 px-8">
          <div className="row">
            <div className="col-12">
              
            </div>
          </div>
        </div> */}

        <div className="py-3 mb-6 mt-6">
          <div className="container">
            <div className="row mb-2">
              <div className="col-lg-4 col-md-6 ">
                <div className="sticky-top pt-5 pl-5">
                  <h5 class="mb-3 fs-0"></h5>
                  <h5>
                    <strong>SMART SEARCH</strong>
                  </h5>

                  {/* <Search /> */}
                  <input
                    type="search"
                    style={{ "font-size": "18px" }}
                    className="form-control input-lg input_border "
                    placeholder="Search by location..."
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />

                  <div className="container mt-4">
                    <h5 className="">
                      <strong>By Locations</strong>
                    </h5>
                    <form>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb1" name="cb1" type="checkbox" />
                          <label for="cb1" className=""></label>
                          <small>Maitama</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb2" name="cb2" type="checkbox" />
                          <label for="cb2" className=""></label>
                          <small>Jabi</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb3" name="cb3" type="checkbox" />
                          <label for="cb3" className=""></label>
                          <small>Gwarinpa</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb4" name="cb4" type="checkbox" />
                          <label for="cb4" className=""></label>
                          <small>Asokoro</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb5" name="cb5" type="checkbox" />
                          <label for="cb5" className=""></label>
                          <small>Gudu</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb6" name="cb6" type="checkbox" />
                          <label for="cb6" className=""></label>
                          <small>Jahi</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb7" name="cb7" type="checkbox" />
                          <label for="cb7" className=""></label>
                          <small>Wuse 2</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb8" name="cb8" type="checkbox" />
                          <label for="cb8" className=""></label>
                          <small>Katampe</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="container mt-4">
                    <h5 className="">
                      <strong>Room facilities</strong>
                    </h5>
                    <form>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb9" name="cb9" type="checkbox" />
                          <label for="cb9" className=""></label>
                          <small>Dedicated Kitchen</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb10" name="cb10" type="checkbox" />
                          <label for="cb10" className=""></label>
                          <small>Washer</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb11" name="cb11" type="checkbox" />
                          <label for="cb11" className=""></label>
                          <small>Free WiFi</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb12" name="cb12" type="checkbox" />
                          <label for="cb12" className=""></label>
                          <small>Air conditioning</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb13" name="cb13" type="checkbox" />
                          <label for="cb13" className=""></label>
                          <small>Coffee/tea maker</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="container mt-4">
                    <h5 className="">
                      <strong>Facilities</strong>
                    </h5>
                    <form>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb14" name="cb14" type="checkbox" />
                          <label for="cb14" className=""></label>
                          <small>24-hour front desk</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb15" name="cb15" type="checkbox" />
                          <label for="cb15" className=""></label>
                          <small>Room service</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb16" name="cb16" type="checkbox" />
                          <label for="cb16" className=""></label>
                          <small>Parking Lot</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb17" name="cb17" type="checkbox" />
                          <label for="cb17" className=""></label>
                          <small>Restaurant</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                      <div className="font-1">
                        <div class="zinput zcheckbox">
                          <input id="cb18" name="cb18" type="checkbox" />
                          <label for="cb18" className=""></label>
                          <small>Swimming Pool</small>
                          <small className="float-end color-5"><strong>2</strong></small>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8">
                {/* <form className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control input-lg input_border"
                  placeholder="Search by brand, name, or location"
                  aria-label="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span class="input-group-btn">
                  <button class="btn color-vhospitalilty-b" type="button">
                    <span class="icon-Magnifi-Glass2 fs-4"></span>
                  </button>
                </span>
              </form> */}
                {showProductList}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />

        <section className="p-0 color-white font-1">
          <div className="background-holder overlay overlay-1">
            {/* className="background-holder overlay overlay-gradient"> */}{" "}
          </div>
          <div className="container">
            <div className="row h-half align-items-center py-5 text-center justify-content-center">
              <div className="container mt-6 px-8">
                <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                  <strong>{category.name} Collections</strong>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <div className="alert alert-dark " role="alert">
          <Link to={`/collections`} className="underlink_removal">
            Collections
          </Link>{" "}
          <span class="icon-Arrow-Right2"></span> {category.slug}
        </div>

        <div className="container mt-6 px-8">
          <div className="row">
            <div className="col-12">
              <form className="d-flex input-group w-auto">
                <input
                  type="search"
                  className="form-control input-lg input_border"
                  placeholder="Search by brand, name, or location"
                  aria-label="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span class="input-group-btn">
                  <button class="btn color-vhospitalilty-b" type="button">
                    <span class="icon-Magnifi-Glass2 fs-4"></span>
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>

        <div className="py-3 px-3 mt-6">
          <div className="container text-center ">
            <h4 className="display-5 mb-0">
              Sorry, Property not found under {category.slug}
            </h4>
            <LazyLoadImage
              alt="error_thumbnail"
              className="mb-0"
              effect="blur"
              width="65%"
              src={error_image}
            />
            {/* <div className="row mb-2">{showProductList}</div> */}
          </div>
        </div>
        <ProductViewPagePromo />
        {/* <Footer /> */}
        <Footer></Footer>
      </div>
    );
  }
}

export default ViewProduct;
