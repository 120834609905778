import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import "../../App.css";
import Mapbox from "./MapBox/Mapbox";
import SearchIcon from "@material-ui/icons/Search";
import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core";

import { format } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Map from "react-map-gl";

import Footer from "./Footer";
import Navbar from "../../layouts/frontend/Navbar";
import swal from "sweetalert";
import img from "../../assets/admin/assets/img/logo1.png";
import Search from "../frontend/Search/Search";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../node_modules/react-datepicker/dist/";

import error_image from "../../assets/admin/assets/img/error.jpg";
import FirstNavbar from "../../layouts/frontend/FirstNavbar";

function Home(props) {
  console.log(props);
  console.log(props.location.state);

  const formattedStartDate = format(
    new Date(props.location.state.startDate),
    "dd MMMM yy"
  );
  const formattedEndDate = format(
    new Date(props.location.state.endDate),
    "dd MMMM yy"
  );

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [noOfGuest, setNoOfGuest] = useState("");

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    console.log(startDate.toISOString());
    console.log(endDate.toISOString());
  }

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [viewProduct, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [q, setQ] = useState("");
  const [searchParam] = useState(["brand", "name", "location"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const [foundProduct, setFoundProduct] = useState([]);
  const noOfguestValue = parseInt(props.location.state.noOfguest);
  console.log(noOfguestValue);

  useEffect(() => {
    document.title = "Home";
    axios.get("/api/view-homes").then((res) => {
      if (res.data.status === 200) {
        setProduct(res.data.houses);
        setLoading(false);
      }
    });

    // const data = {
    //   noOfguest: props.location.state.noOfguest,
    //   location: props.location.state.location,
    // };

    const noOfguest1 = props.location.state.noOfguest;
    const location1 = props.location.state.location;

    axios
      .get(`/api/search-this-homes/${noOfguest1}/${location1}`)
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data.houses);
          setFoundProduct(res.data.houses);
          setLoading(false);
        }
      });
  }, []);

  const data = Object.values(viewProduct);

  function search(items) {
    return items.filter((item) => {
      if (item.brand == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.location == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.name == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  // WISHLIST
  const addToWishList = (e, property_id) => {
    e.preventDefault();

    const data = {
      product_id: property_id,
      product_qty: quantity,
    };

    // console.log('added my brother');
    axios.post(`/api/add-to-wishlist`, data).then((res) => {
      if (res.data.status === 200) {
        //Created - Data Inserted
        // history.push("/account/wishlist");
        // swal("Success",res.data.message,"success");
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(res.data.cart);
      } else if (res.data.status === 409) {
        //Already added to cart
        // history.push("/account/wishlist");
        // swal("Success",res.data.message,"success");
        toast(`❤️  ${res.data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(res.data.cart);
      } else if (res.data.status === 401) {
        //Unauthenticated
        swal("Error", res.data.message, "error");
        history.push("/login");
      } else if (res.data.status === 404) {
        //Not Found
        swal("Warning", res.data.message, "warning");
        history.push("/login");
      }
    });
  };

  var display_Productdata = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    display_Productdata = foundProduct.map((item, idx) => {
      return (
        <>
          <div className="col-md-6 col-sm-6 mb-6 align-self-stretch" key={idx}>
            {/* <i className="flag flag-uae"></i> */}
            <Link
              className="color-1 underlink_removal"
              to={`/home/${item.id}`}
              style={{
                "text-transform": "uppercase",
                "text-decoration": "none",
              }}
            >
              <div className="card radius-square">
                <LazyLoadImage
                  alt={item.name}
                  effect="blur"
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                />
                <div className="card-block p-2">
                  <div className="row align-items-center mt-3">
                    <div className="col-12">
                      <div className="color-5 mt-2">
                        <button
                          className="btn btn-light color-5 fs--1 float-end"
                          onClick={(e) => addToWishList(e, item.id)}
                        >
                          <span className="fa  fa-heart fs--1 "></span>
                        </button>
                        <ToastContainer />
                      </div>

                      <p
                        className="fs--4 color-1 font-1"
                        style={{ "text-transform": "uppercase" }}
                      >
                        <strong>{item.name}</strong>
                      </p>
                    </div>
                    <div className="col">
                      <h5 className="fs-4 d-inline-block mb-0 color-vhospitalilty ">
                        NGN {item.selling_price}
                        <span className="color-5 fs--1 fw-400">/ night]</span>
                      </h5>
                    </div>
                  </div>
                  <div className="row my-2">
                    <span className="color-5 fs--1 fw-400 mb-2">
                      {" "}
                      {item.status === 0 ? (
                        <div class="badge badge-warning ">Available</div>
                      ) : (
                        <div class="badge badge-warning ">Not Available</div>
                      )}{" "}
                      | <span className="fa fa-map-marker mr-1"></span>{" "}
                      {item.location}
                    </span>
                    <div className="col-12">
                      <div className="d-inline-block color-vhospitalilty">
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                        <span className="fa fa-star mr-1"></span>
                      </div>
                      <div className="d-inline-block fs--1">
                        {item.category.name}
                      </div>
                      <div>
                        {/* <button type="button" 
                className="btn btn-outline-warning mr-3" onClick={(e) => deleteTestCategory(e, item.id)}>Make Available</button>
                        <ToastContainer /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </>
      );
    });
  }

  if (display_Productdata.length > 0) {
    return (
      <>
        {/* NAVBAR -------------------------- */}

        <FirstNavbar />
        <div className="background-11 color-2 py-0 shadow sticky-top">
          <Navbar />
        </div>

        <section className="font-1 py-5">
          <div className="row pr-4 pl-4 pb-4">
            <div className="col-6" style={{ width: "1500px", margin: "auto" }}>
              <form>
                <div className="input-group">
                  <input
                  disabled
                    type="search"
                    className="form-control input-lg input_border text-center text-uppercase"
                    placeholder={`Properties in ${props.location.state.location} | ${formattedStartDate} - ${formattedEndDate} | ${props.location.state.noOfguest} Guests`}
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />

                  <span className="input-group-btn">
                    <button className="btn color-vhospitalilty-b" type="button">
                      <span className="icon-Magnifi-Glass2 fs-4"></span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          {/* <IconSearchBar /> */}

          <div className="container wrapper ">
            <div className="row">
              <div className="col-12">
                <div className="row pb-3 align-items-center ">
                  <div className="col">
                    <strong>
                      <p className="mb-0 fs-4 mb-2 ">
                        {" "}
                        1 - {foundProduct.length} of {foundProduct.length}{" "}
                        results for
                        <strong>
                          {" "}
                          PROPERTIES UNDER
                          <span className="color-vhospitalilty text-uppercase">
                            {" "}
                            {props.location.state.location}
                          </span>
                        </strong>
                      </p>
                    </strong>
                    <div className="row justify-content-center mb-3">
                      <div className="col-lg-12 ">
                        <strong>Available from </strong>
                        <span class="badge mr-1 badge-default">
                          {" "}
                          {formattedStartDate}
                        </span>{" "}
                        <strong>- </strong>
                        <span class="badge mr-1 badge-default">
                          {formattedEndDate}
                        </span>{" "}
                        <strong>for </strong>{" "}
                        <span class="badge mr-1 badge-default">
                          {" "}
                          {props.location.state.noOfguest}{" "}
                        </span>{" "}
                        <strong>
                          {noOfguestValue == 1 ? "Guest" : "Guests"}{" "}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 text-md-right ">
                    <label for="exampleInputName">Sort by</label>
                    <form className="d-inline-block form-inline">
                      <div className="form-group mr-3">
                        <select
                          onChange={(e) => {
                            setFilterParam(e.target.value);
                          }}
                          className="form-control d-inline-block ml-2"
                          style={{ width: "200px", height: "auto" }}
                        >
                          <option value="All">All Types</option>
                          <option value="Varnern Residence">
                            Varnern Residence
                          </option>
                          <option value="V-Hotel">V-Hotel</option>
                          <option value="V-Apartments">V-Apartments</option>
                          <option value="V-Homes">V-Homes</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mt-3 fixed-wrapper">
                  <div className="col-12"></div>

                  <div className="col-3 hidden-md-down ">
                    <div className=" " style={{"border-radius":"15px", "box-shadow":"0px 6px 18px -9px rgba(0, 0, 0, 0.25)"}}>
                    <Map
                      
                      initialViewState={{
                        latitude: 7.4734988,
                        longitude: 9.0917711,
                        zoom: 5,
                      }}
                      style={{ width: "100%", height: "50%" }}
                      mapStyle="mapbox://styles/asomba/cle2sejt4000901lrzmlmvb2r"
                      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KY}
                      // mapboxAccessToken='pk.eyJ1IjoiYXNvbWJhIiwiYSI6ImNsZHlhZHUxazA1OG4zdnM1b2ZnaDM1a2oifQ.4LO87nJB0nVG8SCyVaOpCg'
                    />
                    </div>
                    <div className="sticky-top pt-5">
                      <h5 class="mb-3 fs-0"></h5>
                        <h5>
                          <strong>SMART SEARCH</strong>
                        </h5>

                        {/* <Search /> */}
                        <input
                          type="search"
                          style={{ "font-size": "18px" }}
                          className="form-control input-lg input_border "
                          placeholder="Search by location..."
                          aria-label="Search"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />

                      </div>
                  </div>
                  <div className="col-lg-6 pl-lg-5 ">
                    <div className="row">{display_Productdata}</div>
                  </div>
                  <div className="col-3">
                  <div className="sticky-top pt-5">
                      <h5 class="mb-3 fs-0"></h5>
                        <h5>
                          <strong>SMART SEARCH</strong>
                        </h5>

                        {/* <Search /> */}
                        <input
                          type="search"
                          style={{ "font-size": "18px" }}
                          className="form-control input-lg input_border "
                          placeholder="Search by location..."
                          aria-label="Search"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />

                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  } else {
    return (
      <>
        {/* NAVBAR -------------------------- */}

        <FirstNavbar />
        <div className="background-11 color-2 py-0 shadow sticky-top">
          <Navbar />
        </div>

        <section className="font-1 py-5">
          <div className="row pr-4 pl-4 pb-4">
            <div className="col-6" style={{ width: "1500px", margin: "auto" }}>
              <form>
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control input-lg input_border text-center text-uppercase"
                    placeholder={`Properties in ${props.location.state.location} | ${formattedStartDate} - ${formattedEndDate} | ${props.location.state.noOfguest} Guests`}
                    aria-label="Search"
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                  <span className="input-group-btn">
                    <button className="btn color-vhospitalilty-b" type="button">
                      <span className="icon-Magnifi-Glass2 fs-4"></span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          {/* <IconSearchBar /> */}

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row pb-3 align-items-center">
                  <div className="col">
                    <strong>
                      <p className="mb-0 fs-4 mb-2 ">
                        {" "}
                        1 - {foundProduct.length} of {foundProduct.length}{" "}
                        results for
                        <strong>
                          {" "}
                          PROPERTIES UNDER
                          <span className="color-vhospitalilty text-uppercase">
                            {" "}
                            {props.location.state.location}
                          </span>
                        </strong>
                      </p>
                    </strong>
                    <div className="row justify-content-center mb-3">
                      <div className="col-lg-12 ">
                        <strong>Available from </strong>
                        <span class="badge mr-1 badge-default">
                          {" "}
                          {formattedStartDate}
                        </span>{" "}
                        <strong>- </strong>
                        <span class="badge mr-1 badge-default">
                          {formattedEndDate}
                        </span>{" "}
                        <strong>for </strong>{" "}
                        <span class="badge mr-1 badge-default">
                          {" "}
                          {props.location.state.noOfguest}{" "}
                        </span>{" "}
                        <strong>Guest </strong>
                      </div>
                      {/* <div class="btn-group">
                      <button class="btn btn-light btn-capsule">Check in: {props.location.state.startDate}</button>
                      <button class="btn btn-light btn-capsule">Check out: {props.location.state.endDate}</button>
                      <button class="btn btn-light btn-capsule">No of Guest: {props.location.state.noOfguest}</button>
                    </div> */}
                    </div>
                  </div>
                  <div className="col-md-5 text-md-right ">
                    <label for="exampleInputName">Sort by</label>
                    <form className="d-inline-block form-inline">
                      <div className="form-group mr-3">
                        <select
                          onChange={(e) => {
                            setFilterParam(e.target.value);
                          }}
                          className="form-control d-inline-block ml-2"
                          style={{ width: "200px", height: "auto" }}
                        >
                          <option value="All">All Types</option>
                          <option value="Varnern Residence">
                            Varnern Residence
                          </option>
                          <option value="V-Hotel">V-Hotel</option>
                          <option value="V-Apartments">V-Apartments</option>
                          <option value="V-Homes">V-Homes</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col-12"></div>
                  <div className="container text-center">
                    <h4 className="display-5 mb-0 mt-4">
                      Sorry, No Property found
                    </h4>
                    <LazyLoadImage
                      alt="error_thumbnail"
                      effect="blur"
                      width="65%"
                      src={error_image}
                    />

                <div className="row  mb-8 text-center">
                    <div className="float-left">
                      <Link
                        to="/all-property-avail"
                        className="btn  btn-lg mt-0 btn-dark underlink_removal"
                      >
                        Back to Browse Property
                      </Link>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FOOTER */}
        <Footer></Footer>
      </>
    );
  }
}

export default Home;
