import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/frontend/Navbar";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Footer from "../Footer";
import {
  MDBBadge,
} from "mdb-react-ui-kit";
import img from "../../../assets/admin/assets/img/logo1.png";
import error_image from "../../../assets/admin/assets/img/error.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import '../../../../node_modules/react-toastify/dist/ReactToastify.css'

function WishList(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [wishlist, setWishList] = useState([]);
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }

    axios.get(`/api/view-wishlist`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setWishList(res.data.wish);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [props.match.params.wishlist, history]);

  // Remove cart item
  const deleteCartItem = (e, wish_id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Removing";

    axios.delete(`/api/delete-wishitem/${wish_id}`).then((res) => {
      if (res.data.status === 200) {
        toast(`❤️  ${res.data.wish_data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        history.push("/account/wishlist");
        // swal("Success", res.data.wish_data.message, "success");
        thisClicked.closest("a").remove();
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        thisClicked.innerText = "Remove";
      }
    });
  };

  // Add item to cart
  const submitAddtocart = (e, wish_id) => {
    e.preventDefault();

    // wishlist.map((item ) => {
    //   if (item === wishlist.product_id){
    //     return item
    //   }
    // })

    const data = {
      product_id: wish_id,
      product_qty: quantity,
    };

    console.log(data);
    axios.post(`/api/add-to-cart`, data).then((res) => {
      if (res.data.status === 200) {
        //Created - Data Inserted
        history.push("/cart");
        swal("Success", res.data.message, "success");
      } else if (res.data.status === 409) {
        //Already added to cart
        history.push("/cart");
        swal("Success", res.data.message, "success");
      } else if (res.data.status === 401) {
        //Unauthenticated
        swal("Error", res.data.message, "error");
        history.push("/login");
      } else if (res.data.status === 404) {
        //Not Found
        swal("Warning", res.data.message, "warning");
        history.push("/login");
      }
    });


  };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var wishlist_HTML = "";

    wishlist_HTML = wishlist.map((item, idx) => {
      // totalCartPrice += item.product.selling_price * item.product_qty;
      // totalCartPrice += item.product.selling_price * 1;
      return (
        <>
       
            <a
              className="row align-items-center border color-9 mb-3 py-3 mx-0 underlink_removal"
              key={idx}
            >
              <div className="col-lg-3">
              <Link to={`/home/${item.product_id}`}>
              <LazyLoadImage
              alt="error_thumbnail"
              className="mb-0"
              effect="blur"
              width="100%"
              src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.wishlist_product.image}`}
            />
         
              </Link>
              </div>
              <div className="col-lg-4 color-1">
                <p>{item.wishlist_product.name}</p>
                <div className="color-info">
                  <span className="fa fa-diamond mr-1"></span>
                  <span className="fa fa-diamond mr-1"></span>
                  <span className="fa fa-diamond mr-1"></span>
                  <span className="fa fa-diamond mr-1"></span>
                  <span className="color-5 fs--1">
                    {item.wishlist_product.brand}
                  </span>
                </div>
                {item.wishlist_product.status === 0 ? (
                  <MDBBadge className="ms-2">Available</MDBBadge>
                ) : (
                  <MDBBadge className="ms-2">Not Available</MDBBadge>
                )}
              </div>
              <div className="col-lg-5 color-1">
                <p className="lead color-vhospitalilty fw-600">
                  NGN {item.wishlist_product.selling_price}
                  <span className="color-5 fs--1 fw-400">/ night]</span>
                </p>
                <div className="row mb-3">
                  
                <div class="btn-group mb-0 mt-0">
                  <button class="btn btn-dark" onClick={(e) => deleteCartItem(e, item.id)}>REMOVE</button>
                  <ToastContainer />
                  <button class="btn color-vh-button"onClick={() => history.push({
                    pathname: '/cart',
                    search: `?query=property=${item.wishlist_product}&checkin=${item.wishlist_product.checkin}&checkout=${item.wishlist_product.checkout}&noOfguest=${item.wishlist_product.qty}`,
                    state: {
                        property: item.wishlist_product, 
                        checkin: `${item.wishlist_product.checkin}`, 
                        checkout:`${item.wishlist_product.checkout}`, 
                        noOfguest:`${item.wishlist_product.qty}`}
                })} >BOOK PROPERTY</button>
                </div>
{/* 
                  
                  <div className="col-md-6">
              <button type="button" 
                class="btn btn-dark btn-lg btn-sm mt-3 mr-3" 
                onClick={(e) => deleteCartItem(e, item.id)}>Remove</button>
                        <ToastContainer />
              </div>

                  

                    <button className="btn btn-lg btn-sm mt-3 color-vh-button" 
                    onClick={(e) => submitAddtocart(e, item.product_id) }>
                      Book Room
                    </button> */}
                  
                
                </div>
                <div className="color-vhospitalilty">
                  <span className="fa fa-star mr-1"></span>
                  <span className="fa fa-star mr-1"></span>
                  <span className="fa fa-star mr-1"></span>
                  <span className="fa fa-star mr-1"></span>
                  <span className="fa fa-star mr-1"></span>
                </div>
                <div className="fs--1">
                  <a className="color-5" href="#">
                    Customers Feedback(25){" "}
                  </a>
                </div>
                {/* <div className="color-5 mt-2">
                <a className="color-5 fs--1" href="#">
                  <span className="fa fa-heart mr-1"></span>Add to Wish
                </a>
              </div> */}
              </div>
            </a>
          
        </>
      );
    });
  }

  if (wishlist_HTML.length > 0) {
    return (
      <div>
                  <div className="my-4">
              <h1 className="fs-6 p-4  mb-4 text-center text-white color-vhospitalilty-b ">
              My Wishlist ({wishlist.length})
              </h1>
        
          </div>
        <div className="">{wishlist_HTML}</div>
        <div className="row mt-3 mb-8 text-center">
          <div className="float-left">
          <Link to="/search-homes" className="btn  btn-lg mt-7 mt-3 btn-dark underlink_removal">
                      Back to Browse Property
                    </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="py-3 ">
          <div className="container text-center ">
            <h4 className="display-5 mb-0">No Property Saved</h4>
            <LazyLoadImage
              alt="error_thumbnail"
              className="mb-6"
              effect="blur"
              width="85%"
              src={error_image}
            />
            <div>
            <div className="">{wishlist_HTML}</div>
            <div className="row  mb-8 text-center">
              <div className="float-left">
              <Link to="/search-homes" className="btn  btn-lg mt-0 btn-dark underlink_removal">
                          Back to Browse Property
                        </Link>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WishList;
