import React from 'react'
import "../App.css"

const Footer = () => {
  return (
    <React.Fragment>

<section class="font-1 py-5 background-11">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 pr-lg-8 pb-5 pb-lg-0">
                            <h5>Latest Trends, Styles &amp; Promotions. Are you on the list?</h5>
                            <form>
                                <div class="input-group">
                                    <input class="form-control background-white" type="email" placeholder="Enter your email" />
                                    <span class="input-group-btn">
                                        <button class="btn b-color-vhospitalilty" type="button">Sign up!</button>
                                    </span>
                                </div>
                            </form>
                            <div class="fs--1 my-2 color-4">By entering your email address, 
                            you agree to receive V-Resort offers, promotions, and other commercial messages. You may unsubscribe at any time. Review our
                                <a href="#">Privacy Policy.</a>
                            </div>
                            <a class="mt-5 d-inline-block mr-2" href="#">
                                <img src="assets/images/app-google.png" />
                            </a>
                            <a class="mt-5 d-inline-block" href="#">
                                <img src="assets/images/app-apple.png" />
                            </a>
                            <div class="mb-3 hidden-sm-up"></div>
                            <a class="p-2 color-4 fs-1 ml-0 ml-sm-3" href="#">
                                <i class="fa fa-instagram"></i>
                            </a>
                            <a class="p-2 color-4 fs-1" href="#">
                                <i class="fa fa-facebook"></i>
                            </a>
                            <a class="p-2 color-4 fs-1" href="#">
                                <i class="fa fa-twitter"></i>
                            </a>
                            <div class="fs--1 mt-1 color-3">&copy; 2022 V-Resort. All rights reserved.</div>
                        </div>
                        <div class="col-lg-2 col-md-4 pb-5 pb-md-0 fs--1">
                            <h6 class="color-2 text-uppercase">Company</h6>
                            <hr class="mt-3 short left color-8" align="left" />
                            <a class="color-4 d-block p-1 pl-0" href="#">About Us</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Careers</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Privacy Policy</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Terms of Use</a>
                            
                        </div>
                        <div class="col-lg-2 col-md-4 pb-5 pb-md-0 fs--1">
                            <h6 class="color-2 text-uppercase">Customer Support</h6>
                            <hr class="mt-3 short left color-8" align="left" />
                            <a class="color-4 d-block p-1 pl-0" href="#">Customer Service</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Contact Us</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Track Order</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">FAQs</a>
                            
                        </div>
                        <div class="col-lg-2 col-md-4 fs--1">
                            <h6 class="color-2 text-uppercase">Quicklinks</h6>
                            <hr class="mt-3 short left color-8" align="left" />
                            <a class="color-4 d-block p-1 pl-0" href="#">FAQs</a>
                            <a class="color-4 d-block p-1 pl-0" href="#">Site Map</a>
                            
                        </div>
                    </div>
                   
                </div>
                
            </section>
    </React.Fragment>
  )
}

export default Footer