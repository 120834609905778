import React, { useEffect, useState } from "react";

import swal from "sweetalert";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import "../../../App.css";
import Navbar from "../../../layouts/frontend/Navbar";

import { GoogleLogin } from 'react-google-login';import {gapi} from 'gapi-script'

import {GooglePersonalizedLoginButton} from 'react-google-identity-gsi'

function Login() {
  const history = useHistory();
  const [loginInput, setLogin] = useState({
    email: "",
    password1: "",
    error_list: [],
  });

  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    const data = {
      email: loginInput.email,
      password1: loginInput.password1,
    };
    thisClicked.innerText = "ooo";

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post("/api/login", data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          swal("Success", res.data.message, "success");
          history.push("/all-property-avail");
          // if (res.data.role === "admin") {
          //   history.push("/admin/dashboard", ...res.data.username);
          // } else {
          //   history.push("/");
          // }
        } else if (res.data.status === 401) {
          thisClicked.innerText = "Login";
          swal("Warning", res.data.message, "warning");
        } else {
          thisClicked.innerText = "Login";
          setLogin({ ...loginInput, error_list: res.data.validation_errors });
        }
      });
    });
  };

  // ---------------------- GOGGLE AUTH -------------------------------------

  // const onSuccess = (res) => {
  //   history.push("/");
  //   console.log('Login sucessful', res)

  // }
  // const onFailure = (res) => {
  //   console.log('Login failed', res)
  // }

  const client_id="858507263518-mdshuhdc622hsf83dnnteei3e1pes7tm.apps.googleusercontent.com"

  // useEffect(() => {
  //   google.account.id.initialize({
  //     client_id: client_id,
  //     callback: handleCallbackResponse
  //   });

  //   google.account.id.renderButton(
  //     document.getElementById("signInDiv"),
  //     {theme: "outline", size: "large"}
  //   )
  // })

  const buttonCustomization = {
    locale: 'en',
    text: 'continue_with',
    size: 'large',
    width: '300',
};

const onUserAuthenticatedWithGoogle = (data) => {
  console.log(data);
};

// -------------------------------------------------

  return (
    <>
      <Navbar />

      {/* <section className="sticky-top background-1  py-2 fs--1 fw-600 font-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 align-self-center mt-4 mb-4">
              <p className="  text-white mb-md-0 font-1 text-center  ">
                V-HOSPITALITY
              </p>
            </div>
           
          </div>
        </div>
      </section> */}

      <div className="">
        <div className="container-fluid font-1">
          <div className="row align-items-center  justify-content-center h-full">
            <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <h3 className=" mb-5 text-center"><strong>Sign In</strong> </h3>
              <a
                class="btn btn-icon btn-block btn-lg btn-danger text-white btn-icon-left"
                href="#"
              >
                <span class="fa fa-google-plus"></span> Log in with Google
              </a>

              {/* <div id="signInDiv"></div> */}
              {/* <GooglePersonalizedLoginButton
                  clientId={client_id}
                  buttonCustomization={buttonCustomization}
                  onUserAuthenticationSucceeded={onUserAuthenticatedWithGoogle}
              /> */}
{/* 
              <GoogleLogin
                clientId="858507263518-mdshuhdc622hsf83dnnteei3e1pes7tm.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
              /> */}

              <p class="lead my-3 text-center">or</p>

              <form>
                <div className="mb-3 ">
                  <input
                    className="form-control input-lg"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={handleInput}
                    value={loginInput.email}
                  />
                  <small className="text-danger">
                    <strong>{loginInput.error_list.email}</strong>
                  </small>
                  {/* <label className="span_label">
                      {loginInput.error_list.email}
                    </label> */}
                </div>
                <input
                  className="form-control  input-lg"
                  name="password1"
                  type="password"
                  placeholder="Password"
                  onChange={handleInput}
                  value={loginInput.password1}
                />
                <small className="text-danger">
                  <strong>{loginInput.error_list.password1}</strong>
                </small>
                {/* <label className="span_label">
                    {loginInput.error_list.password1}
                  </label> */}

                <div className="row mt-3">
                  <div className="col-12">
                    <button
                      type="submit"
                      class="btn btn-primary btn-block enter-btn btn-lg"
                      onClick={(e) => loginSubmit(e)}
                    >
                      Login
                    </button>
                  </div>
                  <div className="col-12 mt-4 mb-4 text-center">
                    <div className="fs--1 d-inline-block">
                      <Link to="./recoverpass">Forgot your password?</Link>
                    </div>
                  </div>
                </div>
                {/* <div className="row align-items-center mt-3">
                    <div className="col text-left">
                      <div className="fs--1 d-inline-block">
                        <Link to="./recoverpass">Forgot your password?</Link>
                      </div>
                    </div>
                    <div className="col text-right">
                    <button
                        type="submit"
                        class="btn btn-primary btn-block enter-btn"
                        onClick={(e) => loginSubmit(e)}
                      >
                        Login
                      </button>
                    </div>
                  </div> */}
              </form>
              <hr className="color-9 mt-2" />
              <div className="fs--1 text-center">
                New user?
                <Link to="/register"> register here</Link>
              </div>
              <hr className="color-9" />{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
