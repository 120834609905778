import React from 'react';
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

const Faqs = () => {
  return (
    <div>
        {/* <h1 className="fs-6 p-4  mt-4 mb-4 text-white color-vhospitalilty-b ">
              Frequenty Asked Questions (FAQ)s
            </h1> */}

            <div class="font-1">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <h2 class="mt-4 mb-7">
                  <strong>Frequenty Asked Questions (FAQ)s</strong>{" "}
                </h2>
                {/* <h4 class="mb-7">Frequently asked questions</h4> */}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 px-lg-5">
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>What payment methods are accepted?</strong>
                  </h5>
                  <p class="color-5 fw-300">Credit cards are the most widely-accepted payment method for properties listed on a platform using Paystack</p>
                  <p class="color-5 fw-300">We also have pay on arrival option for returning clients who have used our services for more than 3 times.</p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>Can I come with my pets?</strong>
                  </h5>
                  <p class="color-5 fw-300">Yes, you can.</p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                    Can I get further reduction on the price tag on the property?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                  It is not possible to have any further reductions on the price.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>Is breakfast included in the price?</strong>
                  </h5>
                  <p class="color-5 fw-300">
                  Each room or accommodation that you can book has its own breakfast policy. If breakfast is included, you will see it listed on the property page when you compare different options to book. If breakfast isn’t included, you can see if the property provides it by checking the available facilities. After you book, this information can also be found in your confirmation email, and you can see it when you view your bookings in your account.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 px-lg-5">
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                      Can I access the place before date of booking?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    No please. We advise that you keep to the initial booked
                    periods and hours.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                      Is there any service to cater for my meals and drinks?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                    takes care of your meals and drinks.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                    I’ve been charged. Do I need to do anything?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    Yes, please check your email for your recipt of payments which will be used to checkin.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                    Can I cancel my booking?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                  Yes, any cancellation fees are determined by the property and listed in our cancellation policy. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
            {/* <MDBAccordion flush initialActive={1} className="mb-8 text-dark">
              <MDBAccordionItem
                className="text-dark"
                collapseId={1}
                headerTitle="Can I come with my pets?"
              >
                Yes, you can.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={2}
                headerTitle="Can I get a refund, in an event of emergency?"
              >
                No, please. We do not refund after payment.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={3}
                headerTitle="Can I access the place before date of booking?"
              >
                No please. We advise that you keep to the initial booked periods
                and hours.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={4}
                headerTitle="Is there any service to cater for my meals and drinks?"
              >
                Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                takes care of your meals and drinks.
              </MDBAccordionItem>
              <MDBAccordionItem
                collapseId={5}
                headerTitle="Does the place have a parking lot?"
              >
                Yes, we have a provision per individual.
              </MDBAccordionItem>
            </MDBAccordion> */}
    </div>
  )
}

export default Faqs