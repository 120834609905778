import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import getCenter from "geolib/es/getCenter";
import Map, { Marker, Popup } from "react-map-gl";

mapboxgl.accessToken = `${process.env.REACT_APP_MAP_BOX_KY}`

export default function Mapbox(props) {

  const coordinates = props.houseprop.map((result) => ({
    longitude: result.long,
    latitude: result.lat,
  }));

  const center = getCenter(coordinates);

  // console.log(props)
  // console.log(center)

  const [selectedLocation, setSelectedLocation] = useState();
  const mapContainer = useRef(null);
  const map = useRef(null);
  // const [lng, setLng] = useState(7.497654);
  // const [lat, setLat] = useState(9.085198);
  const [lng, setLng] = useState(center.longitude);
  const [lat, setLat] = useState(center.latitude);
  const [zoom, setZoom] = useState(12.0);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/asomba/cle2sejt4000901lrzmlmvb2r',
      center: [lng, lat],
      zoom: zoom
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      // setLng(map.current.getCenter().lng.toFixed(10));
      // setLat(map.current.getCenter().lat.toFixed(10));
      // setZoom(map.current.getZoom().toFixed(10));
    });

    {<p onClick={() => setSelectedLocation(map.current)} className="fs-2" style={{ cursor: "pointer" }}>
    🏠
  </p>}



  });


  return (
    <div>
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div ref={mapContainer} className="map-container" />
      
    </div>
  );
}
