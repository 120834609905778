import React from 'react'



import img1 from "../assets/admin/assets/img/1b.jpg";
import img2 from "../assets/admin/assets/img/1a.jpg";
import img3 from "../assets/admin/assets/img/home5.jpg";
import img4 from "../assets/admin/assets/img/5.jpg";
import img5 from "../assets/admin/assets/img/6.jpg";
import img6 from "../assets/admin/assets/img/home5a.jpg";

function RecommendedHomes (){
  return (
    <div className="row ">
    <span classs='d-inline-block'>

     <h2
     class=" fw-400 color-vhospitalilty"
     style={{ "text-transform": "uppercase" }}
     >
     <span class="icon-Home fs-2 "></span> <strong>BY POPULAR DEMANDS</strong>
     </h2>
     </span>
    <section class="text-center py-0 font-1 mb-7">
       <div class="sortable">
           <div class="row sortable-container no-gutters">
               <div class="sortable-item col-lg-4 col-md-4" style={{"padding": "1px"}}>
                   <a class="color-white" href="#">
                       <div class="hoverbox">
                           <img class="w-100" src={img1}/>
                           <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-8">
                               <div class="col-12">
                                   <strong className="fs-4">MAITAMA</strong>
                                   <p>Explore Offers</p>
                               </div>
                           </div>
                       </div>
                   </a>
               </div>
               <div class="sortable-item col-lg-4 col-md-4" style={{"padding": "1px"}}>
                   <a class="color-white" href="#">
                       <div class="hoverbox">
                           <img class="w-100" src={img2}/>
                           <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                               <div class="col-12">
                               <strong className="fs-4">KATAMPE</strong>
                               <p>Explore Offers</p>
                               </div>
                           </div>
                       </div>
                   </a>
               </div>
               <div class="sortable-item col-lg-4 col-md-4" style={{"padding": "1px"}}>
                   <a class="color-white" href="#">
                       <div class="hoverbox">
                           <img class="w-100" src={img3}/>
                           <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                               <div class="col-12">
                               <strong className="fs-4">JABI</strong>
                               <p>Explore Offers</p>
                               </div>
                           </div>
                       </div>
                   </a>
               </div>
               <div class="sortable-item col-lg-6 col-md-4" style={{"padding": "1px"}}>
                   <a class="color-white" href="#">
                       <div class="hoverbox">
                           <img class="w-100" src={img4}/>
                           <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                               <div class="col-12">
                               <strong className="fs-4">LIFECAMP</strong>
                               <p>Explore Offers</p>
                               </div>
                           </div>
                       </div>
                   </a>
               </div>
               <div class="sortable-item col-lg-6 col-md-4" style={{"padding": "1px"}}>
                   <a class="color-white" href="#">
                       <div class="hoverbox">
                           <img class="w-100" src={img5}/>
                           <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                               <div class="col-12 p-0">
                                   <img class="w-100" src={img6}/>
                                   <strong className="fs-4">JAHI</strong>
                                   <p>Explore Offers</p>
                                   {/* <div class="col-12">
                               </div> */}
                               </div>
                           </div>
                       </div>
                   </a>
               </div>

           </div>
       </div>
   </section>
   </div>
  )
}

export default RecommendedHomes;