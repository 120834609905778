import React from 'react'
import ReactDOM from 'react-dom';
import "../../App.css";
import { Link } from "react-router-dom";




class MyHomes extends React.Component {


        constructor () {
            super()
            this.state = {
            name: 'Adam',
            }
            this.render = this.render.bind(this)


            // const [cartItems, setCartItems] = useState([])
        }



    render() {

        console.log(this.props)
        
        const {listingsData} = this.props;

        if(listingsData === undefined || listingsData.length === 0){
            return "No results found"
        }

        console.log(listingsData)
        return listingsData.map((user) => (


            <div className="col-md-4 col-6 col-md-4 col-sm-6 mb-4 align-self-stretch"  key={user.id}>
                {/* <Link to={`/view_home/${user.id}`} state={{user: user}}><h1>test</h1></Link> */}
                {console.log(user)}
            <Link className="color-1" to={`/view_home/${user.id}`} state={{user: user}}
            >
                <div className="card radius-square">
                    <img className="card-img-top w-100" src={user.image} alt={user.image} />
                    <div className="card-block p-2">
                        <div className="row align-items-center mt-3">
                            <div className="col-12">
                                <p>{user.neighborhood}</p>
                            </div>
                            <div className="col">
                                <h5 className="fs-0 d-inline-block mb-0 color-vhospitalilty1">NGN {user.price}</h5>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-12">
                                <div className="d-inline-block color-vhospitalilty1">
                                    <span className="fa fa-star mr-1"></span>
                                    <span className="fa fa-star mr-1"></span>
                                    <span className="fa fa-star mr-1"></span>
                                    <span className="fa fa-star mr-1"></span>
                                    <span className="fa fa-star mr-1"></span>
                                </div>
                                <div className="d-inline-block fs--1">
                                    <a className="color-vhospitalilty1" href="#">(155) </a>|
                                    <a className="color-vhospitalilty1" href="#">Orders (187)</a>
                                </div>
                                <div className="color-vhospitalilty1 mt-2">
                                    <a className="color-vhospitalilty1 fs--1" href="#">
                                        <span className="fa fa-heart mr-1"></span>Add to Wish</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
          ))
        
    
  
    }
   
  }
  
  ReactDOM.render(
    <MyHomes />,
    document.getElementById('root')
  );


export default MyHomes

