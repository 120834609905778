import React from 'react'

const UniqueSellingPoint = () => {
  return (
    <React.Fragment>
        <section class="background-11 py-0 text-center font-1">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 py-5 px-xl-4 px-2">
                            <span class="d-block mb-3 fs-5 color-vhospitalilty icon-Dollar" ></span>
                            <h5 class="fw-400">Pocket Friendly</h5>
                            <p class="color-5 mb-0 fs--1">Optimized but both real estate mangers and users</p>
                        </div>
                        <div class="col-lg-3 col-md-6 py-5 px-xl-4 px-2">
                            <span class="d-block mb-3 fs-5 color-vhospitalilty icon-Plane-2"></span>
                            <h5 class="fw-400">Seamless Transactions</h5>
                            <p class="color-5 mb-0 fs--1">All from your dashboard</p>
                        </div>
                        <div class="col-lg-3 col-md-6 py-5 px-xl-4 px-2">
                            <span class="d-block mb-3 fs-5 color-vhospitalilty icon-Credit-Card2"></span>
                            <h5 class="fw-400">Excellent Support</h5>
                            <p class="color-5 mb-0 fs--1">A dedicated support team to handle any of your issues.</p>
                        </div>
                        <div class="col-lg-3 col-md-6 py-5 px-xl-4 px-2">
                            <span class="d-block mb-3 fs-5 color-vhospitalilty icon-Security-Check"></span>
                            <h5 class="fw-400">Refund Policy</h5>
                            <p class="color-5 mb-0 fs--1">If you are not satisfied, just let us know.</p>
                        </div>
                        <div class="col-12">
                            <hr class="color-9" /> </div>
                    </div>

                </div>

            </section>
    </React.Fragment>
  )
}

export default UniqueSellingPoint