import React from 'react'
import img from '../img/logo.png'
import '../App.css'


class NavbarUser extends React.Component {
    constructor () {
      super()
      this.state = {
        name: 'Adam',
      }

    //   this.render = this.render.bind(this)
    }

    render(){
        console.log(this.props.change);
        return (
            <React.Fragment>
                <div className="znav-container znav-dark" id="znav-container">
                    <div className="container">
                        <nav className="navbar navbar-toggleable-md">
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <div className="hamburger hamburger--emphatic">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </button>
                            <a className="navbar-brand  " href="/view_homes"><img src={img} alt={img} className="nav_logo" />V</a>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav ml-auto">
                                <li>
                                    <form >
                                        <div className="input-group text-center">
                                            <input className="form-control input-sm" type='text' name="search" placeholder="Search Homes by Location..." onChange={this.props.change} />
                                        </div>
                                    </form>
                                </li>
                                    <li>
                                        <a href="/view_homes">Home</a>
                                       
                                    </li>
    
                                    <li>
                                        <a href="/view_homes">Explore</a>
                                       
                                    </li>
    
                                    <li>
                                        <a href="/view_homes">Accounts <span className="fw-800 fs-0 icon-Talk-Man px-1"> </span></a>
                                        <ul className="dropdown dropdown-push-left">
                                        <li>
                                                <a href="#">Notifications</a>
                                            </li>
                                            <li>
                                                <a href="#">Wishlist</a>
                                            </li>
                                            <li>
                                                <a href="#">Help</a>
                                            </li>
                                            <li>
                                                <a href="#">Account Settings</a>
                                            </li>
                                            <li>
                                                <a href="/">Log out</a>
                                            </li>
                             
                                        </ul>
                                    </li>
    
                                
                                    <li>
                                        <a className="btn btn-link btn-sm px-2 color-9" href="/wishlist">
                                            <span className="fw-800 fs-0 icon-Heart"> </span>
                                            <span className="d-inline-block pl-1">0</span>
                                        </a>
                                    </li>
    
                                    <li>
                                        <a className="btn btn-link btn-sm px-2 color-9 pr-md-0" href="/checkout">
                                            <span className="fw-800 fs-0 icon-Add-Cart"> </span>
                                            <span className="d-inline-block pl-1">1</span>
                                        </a>
                                    </li>
    
    
                                </ul>
                            </div>
                        </nav>
                    </div>
    
                </div>
    
            </React.Fragment>
    
        )
    }


}

export default NavbarUser;