import React, { useEffect, useState } from "react";
import Navbar from "./frontend/Navbar";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import Footer from "../components/frontend/Footer";
import { MDBIcon } from "mdb-react-ui-kit";
import img from "../assets/admin/assets/img/logo1.png";
import USideBar from "../components/frontend/UserSettings/USideBar";

import { Switch, Route, Redirect } from "react-router-dom";
import userRoutesList from "../routes/UserRouteList";

const UserMasterLayout = (...props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }

    setLoading(false);

    // return () => {
    //   isMounted = false;
    // };
  }, [history]);


  var display_data = "";
  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="right">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    display_data = <div>
                        
        <Switch>
        {userRoutesList.map((routedata, idx) => {
                return (
                    routedata.component && (
                        <Route 
                            key={idx}
                            path={routedata.path}
                            exact={routedata.exact}
                            name={routedata.name}
                            render={(props) => (
                                <routedata.component {...props} />
                            )}
                        />
                    )
                )
            })}
            <Redirect from="/account" to="/account/dashboard" />
        </Switch>

    </div>
  }

  return (
    <>
      <Navbar />

      <section className="p-0 color-white font-1">
        <div className="background-holder overlay overlay-1">
          {/* className="background-holder overlay overlay-gradient"> */}{" "}
        </div>
        <div className="container">
          <div className="row h-half align-items-center py-5 text-center justify-content-center">
            <div className="col-md-8 col-lg-6">
              <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                <strong>V-DASHBOARD</strong>
              </h1>
              {/* <p className="lead fs-3">Properties I love</p> */}
            </div>
          </div>
        </div>
      </section>

      <div className="alert alert-dark" role="alert">
        <div className="container">
          <h6 className="">
            <Link className="underlink_removal" to={`/all-property-avail`}>
              Home
            </Link>{" "}
            <span class='icon-Arrow-Right2'></span> V-Dashboard
          </h6>
        </div>
      </div>

     

      {/* <section className="pt-4 pb-4">
        <div className="container">
          <h1 className="fs-2 text-dark">My Accounts </h1>
        </div>
      </section> */}
      <div className="container background-11">
        <div className="row background-white">
          <div className="cpl-lg-8 col-lg-4 background-11 p-4 hidden-md-down underlink_removal">
            
            <USideBar {...props} />
            {console.log(...props)}

            
          </div>
          <div className=" col-lg-4 col-lg-8 " >
            
            {display_data}
             
            
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserMasterLayout;
