import React from 'react';
import {Route} from 'react-router-dom';
import UserMasterLayout from './layouts/UserMasterLayout';

function UserRoute({...rest})
{
    return (
        <Route {...rest} render={ (props) => <UserMasterLayout {...props} /> } />
    )
}

export default UserRoute;