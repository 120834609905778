import React from 'react'
import Navbar from '../../layouts/frontend/Navbar';



 const Contact = () => {
  return (
    <>
    <Navbar />
      <h1>Welcome Home</h1>

      <h3>Go to Contact</h3>
    </>
  )
}

export default Contact;
