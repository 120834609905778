import React from 'react'
import UniqueSellingPoint from '../../ReusedComponents/UniqueSellingPoint'

function Footer() {
  return (
    <div>
      <UniqueSellingPoint/>
        <section className="font-1 py-5 background-1 text-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pr-lg-8 pb-5 pb-lg-0">
              <h5 className='color-vhospitalilty'>
                Latest Trends, Styles &amp; Promotions. Are you on our list?
              </h5>
              <form>
                <div className="input-group ">
                  <input
                    className="form-control background-white"
                    type="email"
                    placeholder="Enter your email"
                  />
                  <span className="input-group-btn">
                    <button className="btn color-vhospitalilty" type="button">
                      Sign up!
                    </button>
                  </span>
                </div>
              </form>
              <div className="fs--1 my-2 color-4 ">
                By entering your email address, you agree to receive V-Resort
                offers, promotions, and other commercial messages. You may
                unsubscribe at any time. Review our
                <a href="#">Privacy Policy.</a>
              </div>
              {/* <a className="mt-5 d-inline-block mr-2" href="#">
                <img src="assets/images/app-google.png" />
              </a>
              <a className="mt-5 d-inline-block" href="#">
                <img src="assets/images/app-apple.png" />
              </a>
              <div className="mb-3 hidden-sm-up"></div> */}
              {/* <a className="p-2 color-4 fs-1 ml-0 ml-sm-3" href="#">
                <i className="fa fa-instagram"></i>
              </a>
              <a className="p-2 color-4 fs-1" href="#">
                <i className="fa fa-facebook"></i>
              </a>
              <a className="p-2 color-4 fs-1" href="#">
                <i className="fa fa-twitter"></i>
              </a> */}
              <div className="fs--1 mt-1 text-white ">
                &copy; 2023 V-Hospitality. All rights reserved.
              </div>
            </div>
            <div className="col-lg-2 col-md-4 pb-5 pb-md-0 fs--1 text-white">
              <h6 className="color-vhospitalilty text-uppercase">Company</h6>
              <hr className="mt-3 short left color-8" align="left" />
              <a className="color-vhospitalilty  underlink_removal d-block p-1 pl-0" href="#">
                About Us
              </a>
              <a className="color-vhospitalilty underlink_removal  d-block p-1 pl-0" href="#">
                Careers
              </a>
              <a className="color-vhospitalilty underlink_removal  d-block p-1 pl-0" href="#">
                Privacy Policy
              </a>
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                Terms of Use
              </a>
            </div>
            <div className="col-lg-2 col-md-4 pb-5 pb-md-0 fs--1">
              <h6 className="color-vhospitalilty text-uppercase">Customer Support</h6>
              <hr className="mt-3 short left color-8" align="left" />
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                Customer Service
              </a>
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                Contact Us
              </a>
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                Track Order
              </a>
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                FAQs
              </a>
            </div>
            <div className="col-lg-2 col-md-4 fs--1">
              <h6 className="color-vhospitalilty text-uppercase">Quicklinks</h6>
              <hr className="mt-3 short left color-8" align="left" />
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                FAQs
              </a>
              <a className="color-vhospitalilty underlink_removal d-block p-1 pl-0" href="#">
                Site Map
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer