import React, { Component } from 'react'

class WhiteNavbar extends Component {
    render() {
        return (
            <div>
                <hr class="color-9 m-0" />
                <div class="background-10">
                    <div class="container font-1 fs--1 py-1 color-6">
                        <a href="/">Home </a>
                        <a href="/vview_homes">Search Homes</a>/ all</div>
                </div>
                <hr class="color-9 m-0" />
            </div>
        )
    }
}

export default WhiteNavbar