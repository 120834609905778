import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import "../../App.css";
import { usePaystackPayment } from 'react-paystack';
import Navbar from "../../layouts/frontend/Navbar";
import Footer from "./Footer";

function Checkout() {
  var totalCartPrice = 0;
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]);
  const [checkoutInput, setCheckoutInput] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });

  const history = useHistory();
  if (!localStorage.getItem("auth_token")) {
    history.push("/");
    swal("Warning", "Login to goto Cart Page", "error");
  }

  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/view-cart`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setCart(res.data.cart);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [history]);

  const handleInput = (e) => {
    e.persist();
    setCheckoutInput({ ...checkoutInput, [e.target.name]: e.target.value });
  };

//   PAYSTACK INTEGRATION -----------------------------------------------
const config = {
    reference: (new Date()).getTime().toString(),
    email: "asomba505@gmail.com",
    amount: 40000,
    publicKey: 'pk_test_4cbcc522f8236124791c28720653064663a5dc12',
};

const initializePayment = usePaystackPayment(config);

const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    console.log(reference.redirecturl);
    
    var data = {
        firstname: checkoutInput.firstname,
        lastname: checkoutInput.lastname,
        phone: checkoutInput.phone,
        email: checkoutInput.email,
        address: checkoutInput.address,
        city: checkoutInput.city,
        state: checkoutInput.state,
        zipcode: checkoutInput.zipcode,
        payment_mode: "paystack",
        payment_id: reference.redirecturl,
      };


      var paystackDetails = {
        message: reference.message,
        redirecturl: reference.redirecturl,
        reference: reference.reference,
        status: reference.status,
        trans: reference.trans,
        transaction: reference.transaction,
        trxref: reference.trxref,
        payment_mode: "paystack",
      }

    axios.post(`/api/place-order`, data).then(place_res=>{
        if(place_res.data.status === 200)
        {
            swal("Order Placed Successfully",place_res.data.message,"success");
            history.push('/thank-you');
            axios.post(`/api/save_paysack_details`, paystackDetails).then(paystackDetails_res=>{
                if(paystackDetails_res.data.status === 200)
                {
                    console.log(paystackDetails_res.data.message)
                }
            });
        }
    });
  };


  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

// -----------------------------------------------------------------------

  const submitOrder = (e, payment_mode) => {
    e.preventDefault();

    var data = {
      firstname: checkoutInput.firstname,
      lastname: checkoutInput.lastname,
      phone: checkoutInput.phone,
      email: checkoutInput.email,
      address: checkoutInput.address,
      city: checkoutInput.city,
      state: checkoutInput.state,
      zipcode: checkoutInput.zipcode,
      payment_mode: payment_mode,
      payment_id: "",
    };

    

    switch (payment_mode) {
        case "cod":
            axios.post(`/api/place-order`, data).then((res) => {
                if (res.data.status === 200) {
                  swal("Order Placed Successfully", res.data.message, "success");
                  setError([]);
                  history.push("/thank-you");
                } else if (res.data.status === 422) {
                  swal("All fields are mandatory", "", "error");
                  setError(res.data.errors);
                }
              });
            break;

        case "razorpay":
            axios.post(`/api/validate-order`, data).then(res=>{
                
                if(res.data.status === 200)
                {
                    initializePayment(onSuccess, onClose)
                }

                else if (res.data.status === 422)
                {
                    swal("All fields are mandetory","","error");
                    setError(res.data.errors);
                }
            });
            break;

        case "payonline":
            
            break;
    
        default:
            break;
    }
  };

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  }

  var cart_HTML = "";
  if (cart.length > 0) {
    cart_HTML = (
      <div>
        <div className="row bodyShaper">
          <div className="cpl-lg-8 col-lg-8">
            <div className="card">
              <div className="card-header color-vhospitalilty-2" >
                <h4 >Basic Information</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label> First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        onChange={handleInput}
                        value={checkoutInput.firstname}
                        className="form-control"
                      />
                      <small className="text-danger">{error.firstname}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label> Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        onChange={handleInput}
                        value={checkoutInput.lastname}
                        className="form-control"
                      />
                      <small className="text-danger">{error.lastname}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label> Phone Number</label>
                      <input
                        type="number"
                        name="phone"
                        onChange={handleInput}
                        value={checkoutInput.phone}
                        className="form-control"
                      />
                      <small className="text-danger">{error.phone}</small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label> Email Address</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInput}
                        value={checkoutInput.email}
                        className="form-control"
                      />
                      <small className="text-danger">{error.email}</small>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label> Full Address</label>
                      <textarea
                        rows="3"
                        name="address"
                        onChange={handleInput}
                        value={checkoutInput.address}
                        className="form-control"
                      ></textarea>
                      <small className="text-danger">{error.address}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        onChange={handleInput}
                        value={checkoutInput.city}
                        className="form-control"
                      />
                      <small className="text-danger">{error.city}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        onChange={handleInput}
                        value={checkoutInput.state}
                        className="form-control"
                      />
                      <small className="text-danger">{error.state}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        name="zipcode"
                        onChange={handleInput}
                        value={checkoutInput.zipcode}
                        className="form-control"
                      />
                      <small className="text-danger">{error.zipcode}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-lg-4 ">
            <table className="table table-bordered">
              <thead>
                <tr className="color-vhospitalilty-2">
                  <th width="50%">Product</th>
                  <th>Price</th>
                  <th>No. of Occupants</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item, idx) => {
                  totalCartPrice +=
                    item.product.selling_price * 1;
                  return (
                    <tr key={idx}>
                      <td>{item.product.name}</td>
                      <td>{item.product.selling_price}</td>
                      <td>{item.product_qty}</td>
                      <td>{item.product.selling_price * 1}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="2" className="text-end fw-bold">
                    Grand Total
                  </td>
                  <td colSpan="2" className="text-end fw-bold">
                    {totalCartPrice}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-end mb-3">

                <div class="btn-group mb-5 mt-3 text-center">
                  <button class="btn btn-dark text-uppercase p-3" onClick={(e) => submitOrder(e, "cod")}> Pay Cash on Arrival</button>
                  <button class="btn color-vh-button text-uppercase p-3" onClick={(e) => submitOrder(e, "razorpay")}>Pay with Paystack</button>
                </div>

                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    );
  } else {
    cart_HTML = (
      <div>
        <div className="card card-body py-5 text-center shadow-sm">
          <h4>You Have No Roomed Booked Yet, Browse Our <Link to="/">Listing</Link></h4>
        </div>
      </div>
    );
  }

  return (
    <>
    <Navbar />
      <div className="alert alert-dark" role="alert">
        <div className="container">
          <h6 className="lead">
            <Link className="underlink_removal" to={`/cart`}>
              My Bookings
            </Link>{" "}
            / Checkout
          </h6>
        </div>
      </div>
      <div className="container mt-6 mb-6">
        {cart_HTML}
      </div>
      <Footer />
    </>
  );
}

export default Checkout;
