import React from 'react'



import img1 from "../assets/admin/assets/img/1b.jpg";
import img2 from "../assets/admin/assets/img/1a.jpg";
import img3 from "../assets/admin/assets/img/home5.jpg";
import img4 from "../assets/admin/assets/img/5.jpg";
import img5 from "../assets/admin/assets/img/6.jpg";
import img6 from "../assets/admin/assets/img/home5a.jpg";

function ProductViewPagePromo (){
  return (
    <div className="row">
             
    <section class="pb-0">
      <div class="container">
      <span classs='d-inline-block'>

          <h2
          class=" fw-400 color-vhospitalilty"
          style={{ "text-transform": "uppercase" }}
          >
          <span class="icon-Home fs-2 "></span> <strong>BY POPULAR DEMANDS</strong>
          </h2>
          </span>
          <div class="row">
              <div class="col-lg-4 col-md-6 mb-4">
                  <div class="hoverbox p-3 pt-11 h-100">
                      <div class="background-holder overlay overlay-gradient" > </div>
                      
                      <div class="row h-100 hover-box-content-initial align-items-end">
                          <div class="col mt-11">
                              <h5 class="color-white text-uppercase fw-400 mb-0">MAITAMA</h5>
                          </div>
                      </div>
                      <div class="hoverbox-content row align-items-center color-white text-center">
                          <div class="background-holder overlay overlay-0" > </div>
                          
                          <div class="col-sm-12 p-5">
                              <h4 class="mb-2">MAITAMA</h4>
                              <p class="d-inline-block font-1">Enjoy the freedom of a monthly stay on V-Hospitality</p>
                              <button
                              type="button"
                              
                              className="btn btn-sm fs--1 color-vh-button"
                              href="#"
                              >
                              Explore Offer
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                  <div class="hoverbox p-3 pt-11 h-100">
                      <div class="background-holder overlay overlay-gradient" > </div>
                      
                      <div class="row h-100 hover-box-content-initial align-items-end">
                          <div class="col mt-11">
                              <h5 class="color-white text-uppercase fw-400 mb-0">KATAMPE</h5>
                          </div>
                      </div>
                      <div class="hoverbox-content row align-items-center color-white text-center">
                          <div class="background-holder overlay overlay-0" > </div>
                          
                          <div class="col-sm-12 p-5">
                              <h4 class="mb-2">KATAMPE</h4>
                              <p class="d-inline-block font-1">Enjoy 15% Discount as a Returning  Customer</p>
                              <button
                              type="button"
                              
                              className="btn btn-sm fs--1 color-vh-button"
                              href="#"
                              >
                              Explore Offer
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4">
                  <div class="hoverbox p-3 pt-11 h-100">
                      <div class="background-holder overlay overlay-gradient" > </div>
                      
                      <div class="row h-100 hover-box-content-initial align-items-end">
                          <div class="col mt-11">
                              <h5 class="color-white text-uppercase fw-400 mb-0">JABI</h5>
                          </div>
                      </div>
                      <div class="hoverbox-content row align-items-center color-white text-center">
                          <div class="background-holder overlay overlay-0" > </div>
                          
                          <div class="col-sm-12 p-5">
                              <h4 class="mb-2">JABI</h4>
                              <p class="d-inline-block font-1">Connect with other Travellers within the City</p>
                              <button
                              type="button"
                              
                              className="btn btn-sm fs--1 color-vh-button"
                              href="#"
                              >
                              Explore Offer
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4 align-self-stretch push-lg-8">
                  <div class="hoverbox p-3 pt-11 h-100">
                      <div class="background-holder overlay overlay-gradient" > </div>
                      
                      <div class="row h-100 hover-box-content-initial align-items-end">
                          <div class="col mt-11">
                              <h5 class="color-white text-uppercase fw-400 mb-0">LIFECAMP</h5>
                          </div>
                      </div>
                      <div class="hoverbox-content row align-items-center color-white text-center">
                          <div class="background-holder overlay overlay-0" > </div>
                          
                          <div class="col-sm-12 p-5">
                              <h4 class="mb-2">LIFECAMP</h4>
                              <p class="d-inline-block font-1">Excellence Service Team</p>
                              <button
                              type="button"
                              
                              className="btn btn-sm fs--1 color-vh-button"
                              href="#"
                              >
                              Explore Offer
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-8 mb-4 pull-lg-4">
                  <section class="p-3 pt-11 h-100 color-white">
                      <div class="background-holder overlay overlay-2" > </div>
                      
                      <div>
                          <div class="row h-100 align-items-end">
                              <div class="col">
                                  <h4 class="mb-0 mt-4"> Featured Accomodation</h4>
                                  <p class="d-inline-block font-1">Experience the Awesomeness at V-Hospitality Jahi</p>
                                  <br/>
                                  <button
                                  type="button"
                                  
                                  className="btn btn-sm fs--1 color-vh-button"
                                  href="#"
                                  >
                                  Explore Offer
                                  </button>
                              </div>
                          </div>
                          
                      </div>
                      
                  </section>
              </div>
          </div>
          
      </div>
      
  </section>
  </div>
  )
}

export default ProductViewPagePromo;