import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import "../../../App.css";
import Navbar from "../../../layouts/frontend/Navbar";
import img from "../../../assets/admin/assets/img/logo1.png";

import img1 from "../../../assets/admin/assets/img/1b.jpg";
import img2 from "../../../assets/admin/assets/img/1a.jpg";
import img3 from "../../../assets/admin/assets/img/home5.jpg";
import img4 from "../../../assets/admin/assets/img/5.jpg";
import img5 from "../../../assets/admin/assets/img/6.jpg";
import img6 from "../../../assets/admin/assets/img/home5a.jpg";

import Footer from "../Footer";
import ByLocation from "../../../ReusedComponents/ByLocations";
import RecommendedHomes from "../../../ReusedComponents/RecommendedHomes";
import ProductViewPagePromo from "../../../ReusedComponents/ProductViewPagePromo";
import HomeSlideIntro from "../../../ReusedComponents/HomeSlideIntro";
import FirstNavbar from "../../../layouts/frontend/FirstNavbar";

import Search from '../Search/Search'

function ViewCategory() {
  const [loading, setLoading] = useState(true);
  const [userCategory, setUserCategory] = useState([]);
  const [viewProduct, setProduct] = useState([]);

  const [searchInput, setSearchInput] = useState("")
  const [showSearch, setShowSearch] = useState(false);
  

  // useEffect(() => {
  //   let isMounted = true;
  //   fetch("http://api.vhospitalityapp.v-ng.com/api/get-item")
  //       .then(res => res.json())
  //       .then(
  //           (data) => {
  //               setLoading(false);
  //               setUserCategory(data);
  //           }
  //       )
  //       return () => {
  //         isMounted(false);
  //       };
  // }, [])

  useEffect(() => {
    document.title = "app.vhospitalityservices.com";
    let isMounted = true;
    axios.get("/api/get-item").then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setUserCategory(res.data.category);
          setLoading(false);
        }
      }
      return () => {
        isMounted(false);
      };
    });

    axios.get("/api/view-homes").then((res) => {
      if (res.data.status === 200) {
        setProduct(res.data.houses);
        setLoading(false);
      }
    });
  }, []);

  // const search = () => {
  //   rout
  // }


  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var showCategoryList = "";
    var showHomeByLocation = "";
    showCategoryList = userCategory.map((item, idx) => {
      {
        console.log(showCategoryList);
      }
      return (
        <div class="col-md-6 col-sm-6" key={idx}>
          <Link to={`collections/${item.slug}`}>
            <div class="hoverbox h-100">
              <img
                src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
              />
              <div class="row h-200 hover-box-content align-items-end">
              <div class="col-sm-12">
                  <div class="p-4">
                    <strong className="fs-5 font-1 text-uppercase underlink_removal">{item.name}</strong>

                    {/* <p>Explore Offers</p> */}
                  </div>
                </div>
                {/* <h5 class="color-black text-uppercase fw-400 mb-0">
                  
                </h5> */}
                {/* <div class="col mt-0 color-black ">
                                            <small className="color-warning font-1">{item.name}</small>
                                    </div> */}
              </div>
              <div class="hoverbox-content color-white text-right row align-items-end background-black-30">
                <div class="col-sm-12">
                  <div class="p-4">
                    <strong className="fs-3 font-1 ">Explore Offers</strong>

                    {/* <p>Explore Offers</p> */}
                  </div>
                </div>
              </div>
            </div>

          </Link>
        </div>
      );
    });

    showHomeByLocation = viewProduct.map((item, idx) => {
      return (
        <div className="col-md-4 mb-4" key={idx}>
          <div className="card">
            <div className="card-header">
              <div className="py-2 pl-0 pr-0">
                <div className="media align-items-center">
                  <img
                    className="d-flex mr-3 rounded-circle"
                    src={img}
                    alt="Generic placeholder image"
                    style={{ "max-width": "50px" }}
                  />
                  <div className="media-body l-h-0">
                    <a className="color-1" href="#">
                      <h6 className="mb-0">{item.location}</h6>
                    </a>
                    <span className="fs--1 color-2">
                      May 23 &middot; 6 min read
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  if (showCategoryList.length == 0) {
    return (
      <div>
        <FirstNavbar/>
        <Navbar />

        {/* <Slider /> */}
        <HomeSlideIntro />

        <section>
          <div className="container">
            <div class="container mb-6">
              <div class="row text-center justify-content-center">
                <div class="col-lg-9">
                  <h2 class="mb-4">
                    <strong>V-Hospitality Brand</strong>{" "}
                  </h2>
                  <p class="lead font-1 color-4">
                    V Hospitality brings you comfort, elegance and style. Live
                    in luxury, come in and experience our spacious guest rooms
                    and bathrooms that combines comfort, elegance, style and the
                    latest technology. These details transform V Hospitality
                    into an ideal place; both for leisure and business
                    travelers.
                  </p>
                </div>
              </div>
            </div>

            <section class="p-0 mb-8">
              <div class="container-fluid px-0">
                <div class="row no-gutters">{
                  userCategory.map(user => (
                    <li key={user.id}>
                        {user.name} 
                    </li>
                    ))
                }</div>
              </div>
            </section>

            <div className="row">
              <span classs="d-inline-block">
                <h2
                  class=" fw-400 color-vhospitalilty"
                  style={{ "text-transform": "uppercase" }}
                >
                  <span class="icon-Home fs-2 "></span>{" "}
                  <strong>BY POPULAR DEMANDS</strong>
                </h2>
              </span>
              <section class="text-center py-0 font-1 mb-7">
                <div class="sortable">
                  <div class="row sortable-container no-gutters">
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img1} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-8">
                            <div class="col-12">
                              <strong className="fs-4">MAITAMA</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img2} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                            <div class="col-12">
                              <strong className="fs-4">KATAMPE</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img3} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                            <div class="col-12">
                              <strong className="fs-4">JABI</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-6 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img4} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                            <div class="col-12">
                              <strong className="fs-4">LIFECAMP</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-6 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img5} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                            <div class="col-12 p-0">
                              <img class="w-100" src={img6} />
                              <strong className="fs-4">JAHI</strong>
                              <p>Explore Offers</p>
                              {/* <div class="col-12">
                                        </div> */}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <ProductViewPagePromo />
          </div>
        </section>

        <div class="font-1">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6 text-center">
                <h2 class="mb-7">
                  <strong>Frequenty Asked Questions (FAQ)s</strong>{" "}
                </h2>
                {/* <h4 class="mb-7">Frequently asked questions</h4> */}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 px-lg-5">
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>Can I come with my pets?</strong>
                  </h5>
                  <p class="color-5 fw-300">Yes, you can.</p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                      Can I get a refund, in an event of emergency?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    No, please. We do not refund after payment.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>Does the place have a parking lot?</strong>
                  </h5>
                  <p class="color-5 fw-300">
                    Yes, we have a provision per individual.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 px-lg-5">
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                      Can I access the place before date of booking?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    No please. We advise that you keep to the initial booked
                    periods and hours.
                  </p>
                </div>
                <div class="mb-5">
                  <h5 class="fs-0 mb-3 color-1">
                    <strong>
                      Is there any service to cater for my meals and drinks?
                    </strong>
                  </h5>
                  <p class="color-5 fw-300">
                    Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                    takes care of your meals and drinks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  } else {
    return (
<div>
<FirstNavbar/>
<div className="background-white color-2 py-0 shadow sticky-top">
          <Navbar />
          {/* <input
          disabled
            type="search"
            style={{ "font-size": "18px" }}
            className="form-control input-lg input_border text-center"
            placeholder="“Curating the essence of great living………Experience the V-lifestyle”"
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          /> */}
          <div className="banner__search font-1">
            

            <a className='banner__searchButton' variant='outlined' style={{"margin":"auto"}}>
            “Curating the essence of great living... Experience the V-lifestyle”
                </a>
          </div>
        </div>
        <HomeSlideIntro />

      

        <section>
          <div className="container">
            <div class="container mb-6">
              <div class="row text-center justify-content-center">
                <div class="col-lg-9">
                  <h2 class="mb-4">
                    <strong>V-Hospitality Brand</strong>{" "}
                  </h2>
                  <p class="lead font-1 color-4">
                    V Hospitality brings you comfort, elegance and style. Live
                    in luxury, come in and experience our spacious guest rooms
                    and bathrooms that combines comfort, elegance, style and the
                    latest technology. These details transform V Hospitality
                    into an ideal place; both for leisure and business
                    travelers.
                  </p>
                </div>
              </div>
            </div>

            <section class="p-0 mb-8">
              <div class="container-fluid px-0">
                <div class="row no-gutters">{showCategoryList}</div>
              </div>
            </section>

            <div className="row">
              <span classs="d-inline-block">
                <h2
                  class=" fw-400 color-vhospitalilty"
                  style={{ "text-transform": "uppercase" }}
                >
                  <span class="icon-Home fs-2 "></span>{" "}
                  <strong>BY POPULAR DEMANDS</strong>
                </h2>
              </span>
              <section class="text-center py-0 font-1 mb-7">
                <div class="sortable">
                  <div class="row sortable-container no-gutters">
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img1} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-8">
                            <div class="col-12">
                              <strong className="fs-4">MAITAMA</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img2} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                            <div class="col-12">
                              <strong className="fs-4">KATAMPE</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-4 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img3} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2 py-3">
                            <div class="col-12">
                              <strong className="fs-4">JABI</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-6 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img4} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                            <div class="col-12">
                              <strong className="fs-4">LIFECAMP</strong>
                              <p>Explore Offers</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div
                      class="sortable-item col-lg-6 col-md-4"
                      style={{ padding: "1px" }}
                    >
                      <a class="color-white" href="#">
                        <div class="hoverbox">
                          <img class="w-100" src={img5} />
                          <div class="hoverbox-content color-white justify-content-center row align-items-center color-vhospitalilty-2">
                            <div class="col-12 p-0">
                              <img class="w-100" src={img6} />
                              <strong className="fs-4">JAHI</strong>
                              <p>Explore Offers</p>
                              {/* <div class="col-12">
                                        </div> */}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <ProductViewPagePromo />
          </div>
        </section>



        <Footer />
      </div>
    );
  }
}

export default ViewCategory;
