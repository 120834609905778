import React , {useEffect, useState}from "react";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";



const USideBar = () => {

  return (
    <div className="row">
      
    <div className="sticky-top hidden-md-down ">
        <div className="col-lg-12 ">
    <h1 className="fs-4  p-4 mt-0 mb-0 text-white color-vhospitalilty-b ">My Account</h1>
                            <div className=" pt-3 ">
                                <ul className="font-1 underlink_removal no-style fs--5 lh-5 pl-0" id="nav-elements">
                                    <li className="mt-3 mb-3 ">
                                        <Link  to="/account" className="color-5 p-4 font-1 underlink_removal">My V-Hospitality Profile</Link>
                                    </li>
                                    <li className="mt-3 mb-3 ">
                                    <Link    to="/account/wishlist" className="color-5 p-4 underlink_removal">WishList</Link>
                                    </li>
                                    <li className="mt-3 mb-3 ">
                                    <Link   to="/account/notifications" className="color-5 p-4 underlink_removal">Notifications</Link>
                                    </li>
                                    <li className="mt-3 mb-3 ">
                                    <Link   to="/account/track" className="color-5 p-4 underlink_removal">Track My Bookings</Link>
                                    </li>
                                    <li className="mt-3 mb-3 ">
                                    <Link   to="/account/reviews" className="color-5 p-4 underlink_removal">My Reviews</Link>
                                    </li>
                                    <li className="mt-3 mb-3 ">
                                    <Link   to="/account/faqs" className="color-5 p-4 underlink_removal">FAQs</Link>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
    </div>




                        <div className="col-md-6 ">
        <div style={{ minWidth: "22rem" }} className="mb-8">
        
      </div>
      </div>


    </div>
  );
};

export default USideBar;
