import Home from '../components/frontend/Home';
import ViewHomeProducts from '../components/frontend/HomeComp/ViewHomeProducts';
import About from '../components/frontend/About';
import Contact from '../components/frontend/Contact';
import Register from '../components/frontend/auth/Register';
import Login from '../components/frontend/auth/Login';
import RecoverPassword from '../components/frontend/auth/RecoverPassword';
import Page403 from '../components/errors/403';
import Page404 from '../components/errors/404';
import ViewCategory from '../components/frontend/collections/ViewCategory';
import ViewProduct from '../components/frontend/collections/ViewProduct';
import ProductDetail from '../components/frontend/collections/ProductDetail';
import Cart from '../components/frontend/Cart';
import Checkout from '../components/frontend/Checkout';
import Thankyou from '../components/frontend/Thankyou';

import AllPropertyAvailable from '../components/frontend/AllPropertyAvailable'

import Notification from '../components/frontend/UserSettings/Notifications';
import WishList from '../components/frontend/UserSettings/WishList';

import Mapbox from '../components/frontend/MapBox/Mapbox';

import ViewHomesPage from "../components/view_homes/ViewHomesPage"
import Searchdata from "../components/frontend/Searchdata"
// import HomeBnB from "../components/airbnb/HomeBnB"

import ViewHomes from '../components/view_homes/ViewHomesPage'

// import ImageViewer1 from "../components/frontend/ImageViewer/ReactImageViewer";

// import Test from '../components/frontend/Test';
// import Test2 from '../components/frontend/Test2';


const publicRoutesList = [
    { path: '/', exact: true, name: 'ViewCategory', component: ViewCategory },
    { path: '/search-homes', exact: true, name: 'Home', component: Home },
    { path: '/home/:id', exact: true, name: 'ViewHomeProducts', component: ViewHomeProducts },
    { path: '/about', exact: true, name: 'About', component: About },
    { path: '/contact', exact: true, name: 'Contact', component: Contact },
    { path: '/403', exact: true, name: 'Page403', component: Page403 },
    { path: '/404', exact: true, name: 'Page404', component: Page404 },
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/register', exact: true, name: 'Register', component: Register },
    { path: '/recoverpass', exact: true, name: 'RecoverPassword', component: RecoverPassword },
    { path: '/collections', exact: true, name: 'ViewCategory', component: ViewCategory },
    { path: '/collections/:slug', exact: true, name: 'ViewProduct', component: ViewProduct },
    { path: '/collections/:category/:product', exact: true, name: 'ProductDetail', component: ProductDetail },
    { path: '/cart', exact: true, name: 'Cart', component: Cart },
    
    { path: '/checkout', exact: true, name: 'Checkout', component: Checkout },
    { path: '/thank-you', exact: true, name: 'Thankyou', component: Thankyou },


    { path: '/notifications', exact: true, name: 'Notification', component: Notification },
    { path: '/all-property-avail', exact: true, name: 'AllPropertyAvailable', component: AllPropertyAvailable },


    // { path: '/test', exact: true, name: 'Mapbox', component: Mapbox},


    // { path: '/account/wishlist', exact: true, name: 'WishList', component: WishList },
    
    
    { path: '/view_homes', exact: true, name: 'ViewHomes', component: ViewHomes },
    // { path: '/test2', exact: true, name: 'Test2', component: Test2 },

];

export default publicRoutesList;