import home1 from "../img/assets/home1.jpg"
import home2 from "../img/assets/home2.jpg"
import home3 from "../img/assets/home3.jpg"
import home4 from "../img/assets/home4.jpg" 

var listingsData = [
  {
    id: 1,
    address: "61 Thomas Ave.",
    neighborhood: "Maitama",
    date: '7/1/2019',
    state: 'MO',
    rooms: 3,
    bath: 3,
    price: 160000,
    floorSpace: 3650,
    finished_basement:true,
    two_car_garage:true,
    houseType:'Single Story',
    image: `${home1}`
  },
  {
    id: 2,
    address: "2805 Peterson St.",
    neighborhood: "Gwarinpa",
    date: '10/5/2019',
    state: 'MO',
    rooms: 2,
    bath: 1,
    price: 275000,
    floorSpace: 1650,
    pool:true,
    houseType:'Apartment',
    image: `${home2}`
  },
  {
    id: 3,
    address: "4 Henry Rd.",
    neighborhood: "Jabi",
    date: '9/27/2019',
    state: 'MO',
    rooms: 3,
    bath: 2,
    price: 105000,
    floorSpace: 2250,
    pool:true,
    finished_basement:true,
    two_car_garage:true,
    houseType:'Two Story',
    image: `${home3}`
  },
  {
    id: 4,
    address: "197 Marcus Blvd.",
    neighborhood: "Jabi",
    date: '9/10/2019',
    state: 'MO',
    rooms: 4,
    bath: 3,
    price: 200000,
    floorSpace: 3270,
    pool:true,
    finished_basement:true,
    two_car_garage:true,
    houseType:'Two Story',
    image: `${home4}`
  },
  {
    id: 5,
    address: "4 Analect Pl.",
    neighborhood: 'Maitama',
    date: '6/20/2019',
    state: 'MO',
    rooms: 4,
    bath: 3,
    price: 145000,
    floorSpace: 3400,
    pool: true,
    finished_basement:true,
    two_car_garage:true,
    houseType:'Single Story',
    image: `${home1}`
  },
  {
    id: 6,
    address: "25 Parkway Dr.",
    neighborhood: 'Katampe',
    date: '6/18/2019',
    state: 'MO',
    rooms: 4,
    bath: 2,
    price: 265000,
    floorSpace: 3200,
    finished_basement:true,
    two_car_garage:true,
    houseType:'Two Story',
    image: `${home2}`
  },
  {
    id: 7,
    address: "983 Maxwell Pl",
    neighborhood: 'Jabi',
    date: '5/29/019',
    state: 'MO',
    rooms: 3,
    bath: 2,
    price: 175000,
    floorSpace: 2600,
    pool: true,
    finished_basement:true,
    houseType:'Single Story',
    image: `${home3}`
  },
  {
  id: 8,
  address: "200 Franklin Ave.",
  neighborhood: "Katampe",
  date: '7/4/2019',
  state: 'MO',
  rooms: 3,
  bath: 1,
  price: 310000,
  floorSpace:2000,
  pool: true,
  houseType:'Apartment',
  image: `${home4}`
  },
  {
    id: 9,
    address: "10 Huntsport Rd.",
    neighborhood: 'Maitama',
    date: '8/30/2019',
    state: 'MO',
    rooms: 2,
    bath: 1,
    price: 175000,
    floorSpace: 2100,
    finished_basement:true,
    houseType:'Single Story',
    image: `${home1}`
  },
  {
    id: 10,
    address: "3690 Principle Ln.",
    neighborhood: 'Jabi',
    date: '9/1/2019',
    state: 'MO',
    rooms: 4,
    bath: 4,
    price: 75000,
    floorSpace: 3900,
    finished_basement:true,
    pool:true,
    two_car_garage:true,
    houseType:'Two Story',
    image: `${home1}`
  },
  {
    id: 11,
    address: "2628 Vinci St.",
    neighborhood: "Gwarinpa",
    date: '6/14/2019',
    state: 'MO',
    rooms: 1,
    bath: 1,
    price: 75000,
    floorSpace:1700,
    pool:true,
    houseType:'Apartment',
    image: `${home2}`
  },
  {
    id: 12,
    address: "321 Existential St.",
    neighborhood: 'Jabi',
    date: '7/25/2019',
    state: 'MO',
    rooms: 5,
    bath: 3,
    price: 150000,
    floorSpace: 3350,
    two_car_garage:true,
    houseType:'Two Story',
    image: `${home3}`
  }
]

export default listingsData;
