import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { Button } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import { useHistory } from "react-router-dom";

// DATE PICKER COMPONENT
function Search(props) {
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [noOfGuest, setNoOfGuest] = useState("");

  const [searchInput, setSearchInput] = useState("");

  console.log(props);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    console.log(startDate.toISOString());
    console.log(endDate.toISOString());
  }

  const handleGuest = (e) => {
    setNoOfGuest(e.target.value);
  };

  return (
    <div className="container text-center  p-4">
      <div className="row ">
        <h5 className="color-white mb-3 text-uppercase">Book my stay</h5>
        <input
          type="search"
          style={{ "font-size": "18px", width: "550px", margin: "auto" }}
          className="form-control input-lg input_border text-center mb-3"
          placeholder="Your location"
          aria-label="Search"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />

        <div className="col-12 ">
          <DateRangePicker
            showDateDisplay={false}
            rangeColors={["#CD9933", "#cdff"]}
            color="#000"
            minDate={new Date()}
            ranges={[selectionRange]}
            onChange={handleSelect}
          />

          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
            <label className="color-white mt-3 mb-0 text-uppercase "><span className="mr-3 icon-User fs-4"></span> No of Guest </label>
            <input
              style={{ "font-size": "18px", width: "550px", margin: "auto" }}
              min={1}
              max={10}
              onChange={(e) => setNoOfGuest(e.target.value)}
              value={noOfGuest}
              type="number"
              className="text-center form-control input-lg mt-3"
            />
            <hr className="color-black" />
            <button
              onClick={() =>
                history.push({
                  pathname: "/search-homes",
                  search: `?query=location=${searchInput}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&noOfguest=${noOfGuest}`,
                  state: {
                    location: `${searchInput}`,
                    startDate: `${startDate.toISOString()}`,
                    endDate: `${endDate.toISOString()}`,
                    noOfguest: `${noOfGuest}`,
                  },
                })
              }
              className="mt-2 mb-2 btn color-vhospitalilty-b btn-lg"
              style={{ width: "550px", margin: "auto" }}
            >
              Search Property
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
