// import Navbar from './ReusedComponents/NavBar';
import './App.css';


import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {gapi} from 'gapi-script'


import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import Page403 from './components/errors/403';
import Page404 from './components/errors/404';
import axios from "axios";

import 'bootstrap/dist/js/bootstrap.bundle.js';
import PublicRoute from './PublicRoute';
import UserRoute from './UserRoute';


axios.defaults.baseURL = `${process.env.REACT_APP_LOCALHOST_APP_URL}/`;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;

// for logging out
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})

// 



// app.listen(PORT, () => console.log(`listening on ${PORT}`));




const App = () => {

  const client_id = "858507263518-mdshuhdc622hsf83dnnteei3e1pes7tm.apps.googleusercontent.com";


  useEffect(() => {
    function start(){
      gapi.client.init({
        clientId: client_id,
        scope: ""
      })
    };

    gapi.load('client:auth2', start)
  });

  // var accessToken = gapi.auth.getToken().access_token
  // console.log(accessToken)


  // app.use((req, res, next) => {
  //   res.header('Access-Control-Allow-Origin', '*');
  //   next();
  // });

  // app.get('/api/get-item/', (req, res) => {
  //   request(
  //     { url: 'https://testapp.vhospitalityservices.com' },
  //     (error, response, body) => {
  //       if (error || response.statusCode !== 200) {
  //         return res.status(500).json({ type: 'error', message: err.message });
  //       }

  //       res.json(JSON.parse(body));
  //     }
  //   )
  // });

  // app.get('/api/view-homes', (req, res) => {
  //   request(
  //     { url: 'https://testapp.vhospitalityservices.com' },
  //     (error, response, body) => {
  //       if (error || response.statusCode !== 200) {
  //         return res.status(500).json({ type: 'error', message: err.message });
  //       }

  //       res.json(JSON.parse(body));
  //     }
  //   )
  // });




  const [cartItems, setCartItems] = useState([])


  const handleHomeItem = (homeItem) => {
    const IfHomeExist = cartItems.find((item) => item.id === homeItem.id);
    if (IfHomeExist) {
      setCartItems(cartItems.map((item) => item.id === homeItem.id ?
        { ...IfHomeExist, quantity: IfHomeExist.quantity + 1 } : item))
    } else {
      setCartItems([...cartItems, { ...homeItem, quantity: 1 }])
    }
  }

  const removeHomeItem = (homeItem) => {
    const IfHomeItemExist = cartItems.find((item) => item.id === homeItem.id)
    if (IfHomeItemExist.quantity === 1) {
      setCartItems(cartItems.filter((item) => item.id !== homeItem.id))
    } else {
      setCartItems(cartItems.map((item) => item.id === homeItem.id ?
        { ...IfHomeItemExist, quantity: IfHomeItemExist.quantity - 1 } : item))
    }
  }

  return (

    <div className="App">
      <Router>
        <Switch>

          {/* <AdminPrivateRoute path="/admin" name="Admin" /> */}
          <UserRoute path="/account" name="Account" />
          <PublicRoute path="/" name="ViewCategory" />

          <Route exact path="/403" component={Page403} />

          <Route exact path="/404" component={Page404} />

          <Route path="/login">
            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Login />}
          </Route>

          <Route path="/register">
            {localStorage.getItem('auth_token') ? <Redirect to='/' /> : <Register />}
          </Route>

        </Switch>
      </Router>
    </div>
  );

}

export default App;
