import React, { useEffect, useState } from "react";
import Navbar from "../../../layouts/frontend/Navbar";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import Footer from "../Footer";
import { MDBBadge } from "mdb-react-ui-kit";
import img from "../../../assets/admin/assets/img/logo1.png";
import error_image from "../../../assets/admin/assets/img/error.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Rating } from "react-simple-star-rating";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";

function Reviews(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [wishlist, setWishList] = useState([]);
  const [myreviews, setMyreviews] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "Login to goto Cart Page", "error");
    }

    axios.get(`/api/view-wishlist`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setWishList(res.data.wish);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    axios.get(`/api/my-reviews`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setMyreviews(res.data.user);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [props.match.params.wishlist, history]);

  const tooltipArray = [
    "Passable",
    "Passable+",
    "Pleasant",
    "Pleasant+",
    "Good",
    "Good+",
    "Great",
    "Great+",
    "Awesome",
    "Awesome+",
  ];

  console.log(myreviews);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const openModal = () => {
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
  }

  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  } else {
    var userReviews_HTML = "";

    userReviews_HTML = myreviews.map((item, idx) => {
      return (
        <>
          <a
            className="row align-items-center border color-9 mb-3 py-3 mx-0 underlink_removal"
            key={idx}
          >
            <div className="col-lg-3">
            <LazyLoadImage
                  alt="error_thumbnail"
                  className="mb-0"
                  effect="blur"
                  width="100%"
                  src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${item.image}`}
                />
            </div>
            <div className="col-lg-4 color-1">
              <p className="font-1" style={{ "margin-bottom": "0" }}>
                {item.property_name}
              </p>
              <p
                className="lead color-vhospitalilty fw-600"
                style={{ "margin-bottom": "0" }}
              >
                NGN {item.price}
                <span className="color-5 fs--1 fw-400">/ night]</span>
              </p>
              <span className="color-5 fs--1">
                <strong className="font-1">Vendor</strong>: <MDBBadge className="ms-2">{item.prop_vendor}</MDBBadge> 
              </span>

            </div>
            <div className="col-lg-5 col-sm-12 ">
            <button className="mt-3 btn btn-dark color-white  mr-3"  onClick={openModal}>
                  RATE YOUR EXPERIENCE 🙂{" "}
                </button>
            </div>
          </a>
        </>
      );
    });
  }

  if (userReviews_HTML.length > 0) {
    return (
      <div>
        <div className="my-4">
            <h1 className="fs-6 p-4  mb-4 text-white color-vhospitalilty-b ">
            Pending Reviews {myreviews.length}
            </h1>
      
        </div>
        {/* <div className="my-4">
          <strong className="font-1 ">
            Select the stars to rate the Property
          </strong>
        </div> */}
        <div className="">{userReviews_HTML}</div>
        <div className="row mt-3 mb-8 text-center">
          <div className="float-left">
            <Link
              to="/all-property-avail"
              className="btn  btn-lg mt-7 mt-3 btn-dark underlink_removal"
            >
              Back to Browse Property
            </Link>
          </div>
        </div>
        <PureModal
        width="450px"
          header="Rate property experience"
          footer={
            <div>
              <button className="btn btn-secondary mr-3" onClick={closeModal}>Cancel</button>
              <button className="btn btn-dark">Save</button>
            </div>
          }
          isOpen={modal}

          onClose={() => {
            setModal(false);
            return true;
          }}
        >
          <p>
          <div className="mb-3 ">
          <strong className="font-1 ">
            <small>Select the stars to rate the Property</small>
          </strong>
          </div>
          <div className="color-vhospitalilty ">
                <Rating
                  onClick={handleRating}
                  onPointerEnter={onPointerEnter}
                  onPointerLeave={onPointerLeave}
                  onPointerMove={onPointerMove}
                  size={30}
                  transition
                  allowFraction
                  showTooltip
                  tooltipArray={tooltipArray}
                />
              </div>
          </p>
        </PureModal>
        
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="py-3 ">
          <div className="container text-center ">
          <div className="my-4">
              <h1 className="fs-6 p-4  mb-4 text-white color-vhospitalilty-b ">
              Pending Reviews ({myreviews.length})
              </h1>
        
          </div>
          <h4 className=" mb-4"><strong>You have no property booked, yet 😐</strong></h4>
            {/* <h4 className="display-5 mb-0"></h4> */}
            {/* <LazyLoadImage
              alt="error_thumbnail"
              className="mb-6"
              effect="blur"
              width="65%"
              src={error_image}
            /> */}
            <div>
              <div className="">{userReviews_HTML}</div>
              <div className="row  mb-8 text-center">
                <div className="float-left">
                  <Link
                    to="/all-property-avail"
                    className="btn  btn-lg mt-0 btn-dark underlink_removal"
                  >
                    Back to Browse Property
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reviews;
