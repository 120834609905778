import React, { useState, useEffect } from "react";
import img from "../../assets/admin/assets/img/logo.png";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import Search from "../../components/frontend/Search/Search";


function FirstNavbar() {

  const [showSearch, setShowSearch] = useState(false);

  return (
    <>
      {/*  */}
      <section className="background-1 color-6 py-2 fs--1 fw-600 font-1 hidden-md-down">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10 align-self-center">
              {/* <p className="mb-md-0 fs--1 font-1">
              “Curating the essence of great living………Experience the V-lifestyle”

              </p> */}
            </div>
            <div className="col text-right">
              {/* <Link className="btn btn-outline-warning btn-xs" to={!localStorage.getItem("auth_token") ? ("/login") : ("/all-property-avail")}>
                BOOK MY STAY
              </Link> */}
              <Link className="btn btn-outline-warning btn-xs" onClick={() => setShowSearch(!showSearch)}>
              {showSearch ? "CANCEL" : "BOOK MY STAY"} 
              </Link>

              {showSearch && <Search />}
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default FirstNavbar;
