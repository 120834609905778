import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import Navbar from "../../layouts/frontend/Navbar";
import Footer from "./Footer";
import img from "../../assets/admin/assets/img/logo1.png";
import { MDBIcon } from "mdb-react-ui-kit";
import { usePaystackPayment } from 'react-paystack';
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {format} from "date-fns"

import error_image from "../../assets/admin/assets/img/error.jpg";

function Cart(props) {

  console.log(props)

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [allINI, setAllIni] = useState([]);
  var totalCartPrice = 0;
  var ini = 0;
  var final = 0;

  const formattedStartDate = format(new Date(props.location.state.checkin), "dd MMMM yy")
  const formattedEndDate = format(new Date(props.location.state.checkout), "dd MMMM yy")
  const noOfguestValue = parseInt(props.location.state.noOfguest)

  useEffect(() => {
    let isMounted = true;

    if (!localStorage.getItem("auth_token")) {
      history.push("/");
      swal("Warning", "You need to login to go to see your bookings list", "error");
    }

    axios.get(`/api/view-cart`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setCart(res.data.cart);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    // axios.get(`/api/view-cart`).then((res) => {
    //   if (res.data.status === 200) {
    //     setCart(res.data.cart);

    //   }
    // });

    return () => {
      isMounted = false;
    };
  }, [history]);

  // Decrement
  const handleDecrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: item.product_qty - (item.product_qty > 1 ? 1 : 0),
            }
          : item
      )
    );
    updateCartQuantity(cart_id, "dec");
  };

  // Increment
  const handleIncrement = (cart_id) => {
    setCart((cart) =>
      cart.map((item) =>
        cart_id === item.id
          ? {
              ...item,
              product_qty: item.product_qty + (item.product_qty < 5 ? 1 : 0),
            }
          : item
      )
    );
    updateCartQuantity(cart_id, "inc");
  };

  // UpdateCartQuanity
  function updateCartQuantity(cart_id, scope) {
    axios.put(`/api/cart-updatequantity/${cart_id}/${scope}`).then((res) => {
      if (res.data.status === 200) {
        // swal("Success",res.data.message,"success");
      }
    });
  }

  // Remove cart item
  const deleteCartItem = (e, cart_id) => {
    e.preventDefault();

    const thisClicked = e.currentTarget;
    thisClicked.innerText = "Removing";

    axios.delete(`/api/delete-cartitem/${cart_id}`).then((res) => {
      if (res.data.status === 200) {
        history.push("/cart");
        toast(`❤️  ${res.data.cart_data.message}!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // swal("Success", res.data.cart_data.message, "success");
        thisClicked.closest("a").remove();

        //   ini = res.data.cart_data.productUpdate
        //   var final_ini;

        //   setAllIni(allINI.map( (ini, idx) => {
        //     final_ini = ini.product.selling_price;
        //  }))

        //   final = res.data.cart_data.cartUpdate.product_qty
        //   totalCartPrice +=  final_ini * final;
        //   console.log(final_ini);
        //   console.log(final);
        //   console.log(totalCartPrice);
      } else if (res.data.status === 404) {
        swal("Error", res.data.message, "error");
        thisClicked.innerText = "Remove";
      }
    });
  };


  // ------------------------------------- FOR CHECKING OUT EACH PROPERTY------------------
  var totalCartPrice = 0;
  const [checkout, setCheckout] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]);
  const [checkoutInput, setCheckoutInput] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
  });

  // const history = useHistory();
  if (!localStorage.getItem("auth_token")) {
    history.push("/");
    swal("Warning", "Login to goto Cart Page", "error");
  }

  useEffect(() => {
    let isMounted = true;

    axios.get(`/api/view-cart`).then((res) => {
      if (isMounted) {
        if (res.data.status === 200) {
          setCheckout(res.data.cart);
          setLoading(false);
        } else if (res.data.status === 401) {
          history.push("/");
          swal("Warning", res.data.message, "error");
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [history]);

  const handleInput = (e) => {
    e.persist();
    setCheckoutInput({ ...checkoutInput, [e.target.name]: e.target.value });
  };

  totalCartPrice += props.location.state.property.selling_price + 11500 + 7350

//   PAYSTACK INTEGRATION -----------------------------------------------
const config = {
    reference: (new Date()).getTime().toString(),
    email: "asomba505@gmail.com",
    amount: totalCartPrice,
    publicKey: 'pk_test_4cbcc522f8236124791c28720653064663a5dc12',
};

const initializePayment = usePaystackPayment(config);

const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference);
    // console.log(reference.redirecturl);
    
    var data = {
        firstname: checkoutInput.firstname,
        lastname: checkoutInput.lastname,
        phone: checkoutInput.phone,
        email: checkoutInput.email,
        address: checkoutInput.address,
        city: checkoutInput.city,
        state: checkoutInput.state,
        zipcode: checkoutInput.zipcode,
        payment_mode: "paystack",
        payment_id: reference.redirecturl,

        property_id: props.location.state.property.id,
        property_name: props.location.state.property.name,
        noofguest: props.location.state.property.qty,
        price: props.location.state.property.selling_price,
        arrival_date: "",
        departure_date: "",
        image: props.location.state.property.image,
        prop_vendor: props.location.state.property.category.name,
      };


      var paystackDetails = {
        message: reference.message,
        redirecturl: reference.redirecturl,
        reference: reference.reference,
        status: reference.status,
        trans: reference.trans,
        transaction: reference.transaction,
        trxref: reference.trxref,
        payment_mode: "paystack",
      }

    axios.post(`/api/place-order`, data).then(place_res=>{
        if(place_res.data.status === 200)
        {
            swal("Your booking was successfully",place_res.data.message,"success");
            history.push('/thank-you');
            axios.post(`/api/save_paysack_details`, paystackDetails).then(paystackDetails_res=>{
                if(paystackDetails_res.data.status === 200)
                {
                    console.log(paystackDetails_res.data.message)
                }
            });
        }
    });
  };


  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

// -----------------------------------------------------------------------

  const submitOrder = (e, payment_mode) => {
    e.preventDefault();

    var data = {
      firstname: checkoutInput.firstname,
      lastname: checkoutInput.lastname,
      phone: checkoutInput.phone,
      email: checkoutInput.email,
      address: checkoutInput.address,
      city: checkoutInput.city,
      state: checkoutInput.state,
      zipcode: checkoutInput.zipcode,
      payment_mode: payment_mode,
      // payment_id: "",

      property_id: props.location.state.property.id,
      property_name: props.location.state.property.name,
      noofguest: props.location.state.property.qty,
      price: props.location.state.property.selling_price,
      arrival_date: "",
      departure_date: "",
      image: props.location.state.property.image,
      prop_vendor: props.location.state.property.category.name,
    };

    

    switch (payment_mode) {
        case "cod":
            axios.post(`/api/place-order`, data).then((res) => {
                if (res.data.status === 200) {
                  swal("Order Placed Successfully", res.data.message, "success");
                  setError([]);
                  history.push("/thank-you");
                } else if (res.data.status === 422) {
                  swal("All fields are mandatory", "", "error");
                  setError(res.data.errors);
                }
              });
            break;

        case "razorpay":
            axios.post(`/api/validate-order`, data).then(res=>{
                
                if(res.data.status === 200)
                {
                    initializePayment(onSuccess, onClose)
                }

                else if (res.data.status === 422)
                {
                    swal("All fields are mandetory","","error");
                    setError(res.data.errors);
                }
            });
            break;

        case "payonline":
            
            break;
    
        default:
            break;
    }
  };

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------








  if (loading) {
    return (
      <div className="ReactLoadingCenter" align="center">
        <img src={img} width="120px" />
        <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
      </div>
    );
  }
  var cart_HTML = "";
  cart_HTML = (
    <div>
        {/* // totalCartPrice += item.product.selling_price * item.product_qty; */}
        
          <>
            <a className="col-md-6 col-lg-12 underlink_removal" >
              <div className="row border color-9 mb-3 py-3 mx-0">
                <div className="col-lg-3">
                <Link to={`/home/${props.location.state.property.id}`}>
                  <img
                    className="w-100"
                    src={`${process.env.REACT_APP_LOCALHOST_APP_URL}/${props.location.state.property.image}`}
                  />
                  </Link>
                </div>
                <div className="col-lg-6 color-1">
                  <h5 className="fs-0">Details</h5>
                  <hr className="color-9" />

                  <div className="row ">
                    <div className="col-12 mb-3">
                      Room :{" "}
                      <span className="fw-600 mr-3 fs--2">
                        <div className="badge badge-warning fs--2">
                          {props.location.state.property.name}
                        </div>
                      </span> | <label className="mb-0" for="exampleInputName">
                      No of Guest: {noOfguestValue}
                    </label>
                    </div>
                  </div>

                  <p className="text-dark fs--1 fw-300 ellipses" style={{"text-align":"justify"}}>
                    {props.location.state.property.description}
                  </p>

                  {/* <div className="row align-items-center">
                    <label className="col-12 mb-0" for="exampleInputName">
                      No of Occupants Expected : {item.product_qty}
                    </label>
                    <div className="col-6">
                      <div className="form-control input-lg d-inline-block">
                        <div className="input-group">
                          <button
                            type="button"
                            onClick={() => handleDecrement(item.id)}
                            className="input-group-text"
                          >
                            -
                          </button>
                          <div className="form-control input-lg text-center">
                            {item.product_qty}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleIncrement(item.id)}
                            className="input-group-text"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <p className="fs--1 mb-0 fw-600 color-5">
                        {item.product.selling_price}
                        <span className="color-5 fs--1 fw-400">/ night</span>
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-3 color-1">
                  <hr className="color-9" />
                  <h5 className="fs-0">Price</h5>
                  <p className="fs-3 mb-0 fw-600 color-5">
                        NGN {props.location.state.property.selling_price}
                        <span className="color-5 fs--1 fw-400">/ night</span>
                      </p>
                  {/* <p className="mb-0">NGN {item.product.selling_price}</p> */}
                  {/* <button
                    type="button"
                    onClick={(e) => deleteCartItem(e, item.id)}
                    className="btn btn-sm mt-2  fs--1 color-vh-button"
                    href="#"
                  >
                    <span class="fa fa-trash mr-3"></span> 
                    Remove
                  </button>
                  <ToastContainer /> */}
                </div>
              </div>
            </a>
          </>
        
      
    </div>
  )


  var total_price_checker = "";
  if (props.location.state.property.id) {
    total_price_checker = (
      
      <div>
<div className="row color-1" >
              <div className="col-8">
                <p className="mb-0">Sub Total:</p>
              </div>
              <div className="col-4 text-right">
                <p className="mb-0">{props.location.state.property.selling_price}</p>
              </div>
            </div>
<div className="row color-1" >
              <div className="col-8">
                <p className="mb-0">Cleaning Fee:</p>
              </div>
              <div className="col-4 text-right">
                <p className="mb-0">11500</p>
              </div>
            </div>
<div className="row color-1" >
              <div className="col-8">
                <p className="mb-0">Taxes:</p>
              </div>
              <div className="col-4 text-right">
                <p className="mb-0">7350</p>
              </div>
            </div>
      </div>
    );
  }

  // BUTTONS
  var total_button = "";
  if (props.location.state.property) {
    total_button = (
      <>
        <button onClick={(e) => submitOrder(e, "cod")} className="btn  btn-block mt-3 color-vh-button">
          <div className="col-12 px-0">Pay Cash on Arrival</div>
        </button>
        <button onClick={(e) => submitOrder(e, "razorpay")} className="btn  btn-block mt-3 background-info">
          <div className="col-12 px-0">Pay with Paystack</div>
        </button>
        <Link to="/all-property-avail" className="btn btn-dark btn-block mt-3">
          <div className="col-12 px-0">See Available Property</div>
        </Link>
      </>
    );
  } else {
    total_button = (
      <>
        <Link to="/search-homes" className="btn btn-dark btn-block mt-3">
          <div className="col-12 px-0">See Available Property</div>
        </Link>
      </>
    );
  }

  return (
    <div>
      <Navbar />
      <section className="p-0 color-white font-1">
        <div className="background-holder overlay overlay-1">
          {/* className="background-holder overlay overlay-gradient"> */}{" "}
        </div>
        <div className="container">
          <div className="row h-half align-items-center py-5 text-center justify-content-center">
            <div className="col-md-8 col-lg-6">
            <h1 className=" fs-md-4 mb-3 color-vhospitalilty">
                <strong>MY BOOKINGS</strong>
              </h1>
              <p className="lead fs-3">Simple bookings, easy living</p>
            </div>
          </div>
        </div>
      </section>
      <div className="alert alert-dark" role="alert">
        <div className="container">
          <h6 className="">
            <Link className="underlink_removal" to={`/home/${props.location.state.property.id}`}>
              Back
            </Link>{" "}
            <span class='icon-Arrow-Right2'></span> My Bookings
          </h6>
        </div>
      </div>

      <div className="py-2">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12  my-3"></div> */}
            <div className="col-md-12">

              <section className="pt-0 font-1">
                <div className="container">
                <div className="col">
                <h1 className="fs-4"><strong>My Bookings</strong> - Details</h1>
                    
                    <div className="row justify-content-center mb-3">
                      <div className="col-12  col-md-12 col-sm-12 mt-3">
                      <span>Availabile From: {" "}</span><span class="badge mr-1 badge-default "> {formattedStartDate}</span> - <span class="badge mr-1 badge-default ">{formattedEndDate}</span> {" "}{" "}
                                                
                      </div>
                      {/* <div className="col-12 col-md-12 col-sm-12 ">
                      <span>Check out: {" "}</span><span class="badge mr-1 badge-default float-end">{formattedEndDate}</span> {" "}{" "}
                                                
                      </div> */}
                      <span>
                    <p className="mb-0 fs--4 mb-2 mt-3 ">
                      {" "}
                      No of guest:
                      <strong>
                      {" "}
                        <span className=" text-uppercase"> {noOfguestValue}</span>
                      </strong>
                    </p>
                    </span>

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-9">
                      <div className="row">
                        {/* REmove */}
                        {cart_HTML}

                        <div className="container">
                        <h1 className="fs-4"><strong>Billing Details</strong></h1>
                        <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label> First Name</label>
                      <input
                        type="text"
                        name="firstname"
                        onChange={handleInput}
                        value={checkoutInput.firstname}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.firstname}</small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group  mb-3">
                      <label> Last Name</label>
                      <input
                        type="text"
                        name="lastname"
                        onChange={handleInput}
                        value={checkoutInput.lastname}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.lastname}</small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label> Phone Number</label>
                      <input
                        type="number"
                        name="phone"
                        onChange={handleInput}
                        value={checkoutInput.phone}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.phone}</small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label> Email Address</label>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInput}
                        value={checkoutInput.email}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.email}</small>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label> Full Address</label>
                      <textarea
                        rows="3"
                        name="address"
                        onChange={handleInput}
                        value={checkoutInput.address}
                        className="form-control input-lg"
                      ></textarea>
                      <small className="text-danger">{error.address}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>City</label>
                      <input
                        type="text"
                        name="city"
                        onChange={handleInput}
                        value={checkoutInput.city}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.city}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>State</label>
                      <input
                        type="text"
                        name="state"
                        onChange={handleInput}
                        value={checkoutInput.state}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.state}</small>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group mb-3">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        name="zipcode"
                        onChange={handleInput}
                        value={checkoutInput.zipcode}
                        className="form-control input-lg"
                      />
                      <small className="text-danger">{error.zipcode}</small>
                    </div>
                  </div>
                </div>
                        </div>

                        {/* FAQS */}
                        <div className="mt-6">
                        <h1 className="fs-4"><strong>Frequenty Asked Questions (FAQ)s</strong></h1>

                                <MDBAccordion flush initialActive={1} className=" text-dark">
                                  <MDBAccordionItem
                                    className="text-dark"
                                    collapseId={1}
                                    headerTitle="Can I come with my pets?"
                                  >
                                    Yes, you can.
                                  </MDBAccordionItem>
                                  <MDBAccordionItem
                                    collapseId={2}
                                    headerTitle="Can I get a refund, in an event of emergency?"
                                  >
                                    No, please. We do not refund after payment.
                                  </MDBAccordionItem>
                                  <MDBAccordionItem
                                    collapseId={3}
                                    headerTitle="Can I access the place before date of booking?"
                                  >
                                    No please. We advise that you keep to the initial booked periods
                                    and hours.
                                  </MDBAccordionItem>
                                  <MDBAccordionItem
                                    collapseId={4}
                                    headerTitle="Is there any service to cater for my meals and drinks?"
                                  >
                                    Yes, we do run a top notch Canela restaurant &amp; V-Cafe to
                                    takes care of your meals and drinks.
                                  </MDBAccordionItem>
                                  <MDBAccordionItem
                                    collapseId={5}
                                    headerTitle="Does the place have a parking lot?"
                                  >
                                    Yes, we have a provision per individual.
                                  </MDBAccordionItem>
                                </MDBAccordion>
                        </div>
                      </div>
                    </div>
                    <div className="col ">
         
                      <div className="row sticky-top ">
                        <div className="col-12 border color-9 align-self-start ">
                          <p className="fw-600 my-3 color-1">
                            You have {props.location.state.property.name} items in your booking list
                          </p>
                          <hr className="color-9" />
                          <div className="row color-1">
                            {total_price_checker}

                            <div className="col-12">
                              <hr className="color-9 my-2" />{" "}
                            </div>

                            <div className="col-8">
                              <strong>
                                <p className="mb-0">Total:</p>
                              </strong>
                            </div>
                            <div className="col-4 text-right">
                              <strong>
                                <p className="mb-0">{totalCartPrice}</p>
                              </strong>
                            </div>

                            <div className="col-12">
                              <hr className="color-9 mt-2" />{" "}
                            </div>
                            <div className="col-8">
                              <p className="fw-600 color-warning">
                                Payable Total:
                              </p>
                            </div>
                            <div className="col-4 text-right">
                              <p className="fw-600 color-warning">
                                {totalCartPrice}
                              </p>
                            </div>
                          </div>
                        </div>

                        {total_button}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  );
}

export default Cart;
