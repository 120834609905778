import React from 'react'
import Navbar from '../../layouts/frontend/Navbar'
import { Link } from "react-router-dom";
import "../../../src/App.css"
import img2 from "../../assets/admin/assets/img/premiumrooms2.jpg";
import img from "../../assets/admin/assets/img/logo.png";

function Checkout () {
  <Navbar />
  return (
    <div>
      <section className="py-0 font-1">
                <div className="background-holder overlay overlay-1" > </div>
               
                <div className="container-fluid">
                    <div className="row align-items-center text-center h-full">
                    <div className="container-fluid">
                    <div className="row align-items-center text-center h-full">
                        <div className="col justify-content-center">
                        <img src={img} width="120px" />
                            <h1 className="mb-0 fs-7  color-vhospitalilty">Thank you!</h1>
                            <div className='justify-content-center text-center'><p className="lead text-white fs-3"><strong>We just emailed you your receipt, kindly check your email for confirmation.</strong></p></div>
                            <Link className="btn btn-secondary btn-lg mt-3 color-vh-button-3" to="./collections">SEE BOOKINGS</Link>
                        </div>
                    </div>
                    
                </div>
                    </div>
                    
                </div>
                
            </section>
    </div>
  )
}

export default Checkout