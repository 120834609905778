import React, {useState, useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import Navbar from '../../layouts/frontend/Navbar';
import ReactLoading from "react-loading";

import publicRoutesList from '../../routes/Publicroutelist';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import Footer from '../../components/frontend/Footer';

const FrontendLayout = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Home";
        setLoading(false);
      }, []);



      var display_Productdata = "";
      if (loading) {
        return (
          <div className="ReactLoadingCenter" align="center">
            <ReactLoading type={"bars"} color={"black"} height={80} width={80} />
          </div>
        );
      }
      else {
        display_Productdata = <div>
                        
        <Switch>
            {publicRoutesList.map((routedata, idx) => {
                return (
                    routedata.component && (
                        <Route 
                            key={idx}
                            path={routedata.path}
                            exact={routedata.exact}
                            name={routedata.name}
                            render={(props) => (
                                <routedata.component {...props} />
                            )}
                        />
                    )
                )
            })}
        </Switch>

    </div>
      }
    return (
        <div>
            {/* <Navbar /> */}
            
                {display_Productdata}
                {/* <Footer/> */}

        </div>
    );

}

export default FrontendLayout;